import React, { useEffect, useState, useRef } from 'react';
import AuthLayout from '../../../../layout/AuthLayout';
import { CallWithAuth } from '../../../../action/apiActions';
import RightPanelTopMenu from '../../common/RightPanelTopMenu';
import style from '../../../../assets/styles/documentLogger.module.css';
import { useToast } from '../../common/Toast';
import { GET_DOCUMENT_LOGGERLIST } from '../../../../action/apiPath';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import LockModal from '../../../atoms/Modal/LockModel';

const DocumentLogger = () => {
  const toast = useToast();
  const [importRecords, setImportRecords] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [last, setLast] = useState(10);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [questionBankId, setQuestionBankId] = useState(null);
  const [activeActionId, setActiveActionId] = useState<number | null>(null);
  const actionMenuRef = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [sort, setSort] = useState('');
  const BASE_URL: any = process.env.REACT_APP_API_KEY;
  const navigate = useNavigate();
  const [modalMedium, setModalMedium] = useState(false);
  const [modalModalData, setModalModalData] = useState({
    title: 'Important Reminder',
    description:
      'Please be sure to click the ‘Update Exam’ button in the ‘Test Papers’ Tab to update the changes across all tests that include the these questions.',
    cancelButton: '',
    saveButton: 'Got it!',
    className: 'alertHeaderLayout',
  });
  const [navigationPayload, setNavigationPayload] = useState({});
  const openModalMedium = () => {
    setModalMedium(true);
  };

  const closeModalMedium = () => {
    setModalMedium(false);
  };
  //navigate to the import docs
  const handleImportClick = () => {
    navigate('/import-questions', {
      state: { name: '', doc_id: null },
    });
  };

  //handling search function
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  //handle sorting
  const handleSort = (e: any) => {
    if (sort === '') {
      setSort(e);
    } else if (sort === e) {
      setSort('');
    } else {
      setSort(e);
    }
  };
  const getRotation = (column: string) => {
    if (sort === column) {
      return 'rotate(180deg)';
    }
    return 'rotate(0deg)';
  };
  // Add formatDateTime function
  const formatDateTime = (dateString: any) => {
    const date = new Date(dateString);

    // Format date as MM/DD/YYYY
    const formattedDate = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });

    // Format time as HH:MM AM/PM
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    return `${formattedDate}  |  ${formattedTime}`;
  };

  // Fetch document logs function
  const fetchDocumentLogs = async () => {
    try {
      const url = `${GET_DOCUMENT_LOGGERLIST}?limit=${limit}&offset=${offset}&keyword=${searchQuery}&sort=${sort}`;
      const response = await CallWithAuth('GET', url);
      if (response.status && response.res?.status === 200) {
        const totalResults = response.res.data.count;

        // If the current offset is greater than the total results,
        // reset to first page
        if (offset >= totalResults && totalResults > 0) {
          setOffset(0);
          setCurrentPage(1);
          setLast(Math.min(10, response.res.data.count));
        }
        setImportRecords(response.res.data.results);
        setNextUrl(response.res.data.next);
        setPrevUrl(response.res.data.previous);
        setTotalCount(totalResults);
      }
    } catch (error) {
      console.error('Error fetching document logs:', error);
    }
  };

  useEffect(() => {
    fetchDocumentLogs();
  }, [offset, limit, sort, searchQuery, currentPage]);

  const handleNextPage = () => {
    if (nextUrl) {
      setOffset(offset + 10);
      setCurrentPage(currentPage + 1);
      // Calculate the last count dynamically based on remaining records
      const remainingRecords = totalCount - offset - 10;
      const newLastCount = Math.min(offset + 20, totalCount);
      setLast(newLastCount);
    }
  };

  const handlePrevPage = () => {
    if (prevUrl) {
      setOffset(offset - 10);
      setCurrentPage(currentPage - 1);
      setLast(offset);
    }
  };
  const handleActionClick = (recordId: number) => {
    setActiveActionId(activeActionId === recordId ? null : recordId);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    // Check if the click is outside the action menu
    if (actionMenuRef.current && !actionMenuRef.current.contains(event.target as Node)) {
      setActiveActionId(null); // Close the action menu
    }
  };

  useEffect(() => {
    // Add event listener to detect outside clicks
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleDownloadClick = async (recordId: number, fileType: string) => {
    try {
      const response = await axios({
        url: `${BASE_URL}word_doc_logger/${recordId}/download/${fileType}/`,
        method: 'GET',
        responseType: 'blob',
      });
      const contentDisposition = response.headers['content-disposition']; // Axios normalizes headers to lowercase
      let fileName = ''; // Default file name
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1].replace(/['"]/g, '');
        }
      }

      // Create blob link to download
      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      setActiveActionId(null);
    } catch (error) {
      if (toast) {
        toast.add('Failed to download file. Please try again.', 'error');
      }
    } finally {
    }
  };

  const handleRetry = async (recordId: number) => {
    try {
      const response = await CallWithAuth('POST', `${BASE_URL}word_doc_logger_table/logger/${recordId}/regenerate/`);

      if (response.status && response.res?.status === 200) {
        if (toast) {
          toast.add(response.res.data.message, 'success');
        }
        fetchDocumentLogs();
      }
    } catch (error) {
      if (toast) {
        toast.add('Failed to retry', 'error');
      }
    }
  };

  // The handleReupload function captures the `id` and `name`
  const handleReupload = (doc_id: string, name: string, question_bank: string) => {
    // You can log or use the id and name as needed, then navigate
    // navigate('/import-questions', {
    //   state: { name: name, doc_id: doc_id, current_question_bank: question_bank }, // passing name and doc_id from the row
    // });
    const payload = { name: name, doc_id: doc_id, current_question_bank: question_bank };
    setNavigationPayload(payload);
    openModalMedium();
  };
  const handleNavigation = () => {};
  return (
    <AuthLayout pageName="Document Logger">
      <RightPanelTopMenu setQuestionBankId={setQuestionBankId} title={'Question Import Manager'} />
      <div className={style.question_bank_table_top}>
        <div className={style.question_bank}>
          <div className={style.table_content_report}>
            <div
              className={style.table_contentHeader}
              style={{ display: 'flex', height: '12vh', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <div className={`${style.search_listing} ${style.user_management_search_listing}`}>
                <div className={style.tag_icon}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                      stroke="#212529"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.2598 19.2592L16.1406 16.8594"
                      stroke="#212529"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className={style.input_search}>
                  <input
                    type="search"
                    id="searchInput"
                    placeholder="Search for id, name, and question bank"
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                </div>
              </div>

              <div className={style.import_question}>
                <button className={`${style.import_new_question} ${style.pointer}`} onClick={handleImportClick}>
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.4405 9.39844C20.0405 9.70844 21.5105 11.5584 21.5105 15.6084V15.7384C21.5105 20.2084 19.7205 21.9984 15.2505 21.9984H8.74047C4.27047 21.9984 2.48047 20.2084 2.48047 15.7384V15.6084C2.48047 11.5884 3.93047 9.73844 7.47047 9.40844"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 2.5V15.38"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.3504 13.1484L12.0004 16.4984L8.65039 13.1484"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Import Questions</span>
                </button>
              </div>
            </div>
            {totalCount !== 0 ? (
              <div className={style.sub_head_content_table}>
                <table>
                  <thead>
                    <tr>
                      <th className={` ${style.very_first} ${style.pointer}`} onClick={() => handleSort('id')}>
                        ID
                        <svg
                          width="19"
                          height="12"
                          viewBox="0 0 19 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginLeft: '6px', transform: getRotation('id'), transition: 'transform 0.3s ease' }}
                        >
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th className={` ${style.first} ${style.pointer}`} onClick={() => handleSort('created_at')}>
                        Created At
                        <svg
                          width="19"
                          height="12"
                          viewBox="0 0 19 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            marginLeft: '6px',
                            transform: getRotation('created_at'),
                            transition: 'transform 0.3s ease',
                          }}
                        >
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th className={` ${style.second} ${style.pointer}`} onClick={() => handleSort('name')}>
                        Name
                        <svg
                          width="19"
                          height="12"
                          viewBox="0 0 19 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            marginLeft: '6px',
                            transform: getRotation('name'),
                            transition: 'transform 0.3s ease',
                          }}
                        >
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th className={` ${style.third} ${style.pointer}`} onClick={() => handleSort('question_bank')}>
                        Question Bank
                        <svg
                          width="19"
                          height="12"
                          viewBox="0 0 19 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            marginLeft: '6px',
                            transform: getRotation('question_bank'),
                            transition: 'transform 0.3s ease',
                          }}
                        >
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th className={` ${style.fourth} ${style.pointer}`} onClick={() => handleSort('status')}>
                        Status
                        <svg
                          width="19"
                          height="12"
                          viewBox="0 0 19 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            marginLeft: '6px',
                            transform: getRotation('status'),
                            transition: 'transform 0.3s ease',
                          }}
                        >
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th className={` ${style.fifth} ${style.pointer}`} onClick={() => handleSort('user')}>
                        User
                        <svg
                          width="19"
                          height="12"
                          viewBox="0 0 19 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            marginLeft: '6px',
                            transform: getRotation('user'),
                            transition: 'transform 0.3s ease',
                          }}
                        >
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th className={` ${style.sixth} ${style.pointer}`}>Message</th>
                      <th
                        className={` ${style.seventh} ${style.pointer}`}
                        onClick={() => handleSort('generation_time')}
                      >
                        Generation Time
                        <svg
                          width="19"
                          height="12"
                          viewBox="0 0 19 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            marginLeft: '6px',
                            transform: getRotation('generation_time'),
                            transition: 'transform 0.3s ease',
                          }}
                        >
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th className={` ${style.eighth} ${style.pointer}`} onClick={() => handleSort('total_time')}>
                        Total Time
                        <svg
                          width="19"
                          height="12"
                          viewBox="0 0 19 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            marginLeft: '6px',
                            transform: getRotation('total_time'),
                            transition: 'transform 0.3s ease',
                          }}
                        >
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th className={` ${style.nineth} ${style.pointer}`}>Reupload</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {importRecords.map((record: any) => (
                      <tr key={record.id}>
                        <td className={style.docId}>
                          <span className={style.importRecordId}>{record.id}</span>
                        </td>
                        <td>
                          <span className={style.formatDateTime}>{formatDateTime(record.created_at)}</span>
                        </td>
                        <td>
                          <span className={style.docName}>{record.name}</span>
                        </td>
                        <td>
                          <span className={style.questionbankId}>{record.question_bank}</span>
                        </td>
                        <td>
                          <div className={style.docStatus}>
                            <span
                              className={
                                record.status === 'Success' || record.status === 'success'
                                  ? style.statusSuccess
                                  : record.status === 'Failed' || record.status === 'failed'
                                    ? style.statusFailed
                                    : record.status === 'Generating' || record.status === 'generating'
                                      ? style.statusGenerating
                                      : ''
                              }
                            >
                              {record.status}
                            </span>
                          </div>
                        </td>
                        <td>
                          <span className={style.userName}>{record.user}</span>
                        </td>
                        <td style={{ color: '#F23E2C' }}>
                          <span className={style.messageDisplay}>
                            {' '}
                            {record.message_display}
                            {(record.status === 'failed' || record.status === 'Failed') && (
                              <span
                                className={style.retryLink}
                                data-testid="retry-button"
                                onClick={() => handleRetry(record.id)}
                                style={{
                                  color: '#000000',
                                  fontSize: '12px',
                                  fontWeight: '500',
                                  cursor: 'pointer',
                                  marginLeft: '8px',
                                  textDecoration: 'underline',
                                }}
                              >
                                Retry
                              </span>
                            )}
                          </span>
                        </td>
                        <td>
                          <span className={style.generationTime}>{record.generation_time || '-'}</span>
                        </td>
                        <td>
                          <span className={style.totalTime}>{record.total_time || '-'}</span>
                        </td>
                        <td>
                          <span className={style.reuploadButtonwrap}>
                            {(record.status === 'success' || record.status === 'Success') && (
                              <button
                                className={style.reuploadButton}
                                onClick={() => handleReupload(record.id, record.name, record.question_bank)}
                              >
                                Reupload
                              </button>
                            )}
                          </span>
                        </td>
                        <td>
                          <div className={style.action}>
                            <svg
                              onClick={() => handleActionClick(record.id)}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ cursor: 'pointer' }}
                            >
                              <path
                                d="M12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20C11.4696 20 10.9609 19.7893 10.5858 19.4142C10.2107 19.0391 10 18.5304 10 18C10 17.4696 10.2107 16.9609 10.5858 16.5858C10.9609 16.2107 11.4696 16 12 16ZM12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10ZM12 4C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6C14 6.53043 13.7893 7.03914 13.4142 7.41421C13.0391 7.78929 12.5304 8 12 8C11.4696 8 10.9609 7.78929 10.5858 7.41421C10.2107 7.03914 10 6.53043 10 6C10 5.46957 10.2107 4.96086 10.5858 4.58579C10.9609 4.21071 11.4696 4 12 4Z"
                                fill="#272727"
                              />
                            </svg>
                            {activeActionId === record.id && (
                              <div className={style.action_button} ref={actionMenuRef}>
                                <div
                                  className={style.downloadButton}
                                  data-testid="download-button"
                                  onClick={() => handleDownloadClick(record.id, 'mapping_sheet')}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8 10.2743C7.91967 10.2743 7.84489 10.2615 7.77567 10.2358C7.70644 10.2103 7.64061 10.1663 7.57817 10.1038L5.50517 8.03083C5.40606 7.93161 5.35711 7.81556 5.35833 7.68267C5.35967 7.54978 5.40861 7.43161 5.50517 7.32817C5.60861 7.22483 5.72739 7.17144 5.8615 7.168C5.99572 7.16456 6.11456 7.21456 6.218 7.318L7.5 8.6V3.5C7.5 3.35811 7.54789 3.23933 7.64367 3.14367C7.73933 3.04789 7.85811 3 8 3C8.14189 3 8.26067 3.04789 8.35633 3.14367C8.45211 3.23933 8.5 3.35811 8.5 3.5V8.6L9.782 7.318C9.88122 7.21889 9.99894 7.16994 10.1352 7.17117C10.2715 7.1725 10.3914 7.22483 10.4948 7.32817C10.5914 7.43161 10.6414 7.54872 10.6448 7.6795C10.6483 7.81028 10.5983 7.92739 10.4948 8.03083L8.42183 10.1038C8.35939 10.1663 8.29356 10.2103 8.22433 10.2358C8.15511 10.2615 8.08033 10.2743 8 10.2743ZM4.20517 13C3.86839 13 3.58333 12.8833 3.35 12.65C3.11667 12.4167 3 12.1316 3 11.7948V10.4872C3 10.3453 3.04789 10.2265 3.14367 10.1308C3.23933 10.0351 3.35811 9.98717 3.5 9.98717C3.64189 9.98717 3.76067 10.0351 3.85633 10.1308C3.95211 10.2265 4 10.3453 4 10.4872V11.7948C4 11.8462 4.02139 11.8932 4.06417 11.9358C4.10683 11.9786 4.15383 12 4.20517 12H11.7948C11.8462 12 11.8932 11.9786 11.9358 11.9358C11.9786 11.8932 12 11.8462 12 11.7948V10.4872C12 10.3453 12.0479 10.2265 12.1437 10.1308C12.2393 10.0351 12.3581 9.98717 12.5 9.98717C12.6419 9.98717 12.7607 10.0351 12.8563 10.1308C12.9521 10.2265 13 10.3453 13 10.4872V11.7948C13 12.1316 12.8833 12.4167 12.65 12.65C12.4167 12.8833 12.1316 13 11.7948 13H4.20517Z"
                                      fill="black"
                                    />
                                  </svg>
                                  <span>Download Mapping Sheet</span>
                                </div>
                                <div
                                  className={style.downloadButton}
                                  data-testid="download-button"
                                  onClick={() => handleDownloadClick(record.id, 'passage_doc')}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8 10.2743C7.91967 10.2743 7.84489 10.2615 7.77567 10.2358C7.70644 10.2103 7.64061 10.1663 7.57817 10.1038L5.50517 8.03083C5.40606 7.93161 5.35711 7.81556 5.35833 7.68267C5.35967 7.54978 5.40861 7.43161 5.50517 7.32817C5.60861 7.22483 5.72739 7.17144 5.8615 7.168C5.99572 7.16456 6.11456 7.21456 6.218 7.318L7.5 8.6V3.5C7.5 3.35811 7.54789 3.23933 7.64367 3.14367C7.73933 3.04789 7.85811 3 8 3C8.14189 3 8.26067 3.04789 8.35633 3.14367C8.45211 3.23933 8.5 3.35811 8.5 3.5V8.6L9.782 7.318C9.88122 7.21889 9.99894 7.16994 10.1352 7.17117C10.2715 7.1725 10.3914 7.22483 10.4948 7.32817C10.5914 7.43161 10.6414 7.54872 10.6448 7.6795C10.6483 7.81028 10.5983 7.92739 10.4948 8.03083L8.42183 10.1038C8.35939 10.1663 8.29356 10.2103 8.22433 10.2358C8.15511 10.2615 8.08033 10.2743 8 10.2743ZM4.20517 13C3.86839 13 3.58333 12.8833 3.35 12.65C3.11667 12.4167 3 12.1316 3 11.7948V10.4872C3 10.3453 3.04789 10.2265 3.14367 10.1308C3.23933 10.0351 3.35811 9.98717 3.5 9.98717C3.64189 9.98717 3.76067 10.0351 3.85633 10.1308C3.95211 10.2265 4 10.3453 4 10.4872V11.7948C4 11.8462 4.02139 11.8932 4.06417 11.9358C4.10683 11.9786 4.15383 12 4.20517 12H11.7948C11.8462 12 11.8932 11.9786 11.9358 11.9358C11.9786 11.8932 12 11.8462 12 11.7948V10.4872C12 10.3453 12.0479 10.2265 12.1437 10.1308C12.2393 10.0351 12.3581 9.98717 12.5 9.98717C12.6419 9.98717 12.7607 10.0351 12.8563 10.1308C12.9521 10.2265 13 10.3453 13 10.4872V11.7948C13 12.1316 12.8833 12.4167 12.65 12.65C12.4167 12.8833 12.1316 13 11.7948 13H4.20517Z"
                                      fill="black"
                                    />
                                  </svg>
                                  <span>Download Passage Document</span>
                                </div>
                                <div
                                  className={style.downloadButton}
                                  data-testid="download-button"
                                  onClick={() => handleDownloadClick(record.id, 'question_doc')}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8 10.2743C7.91967 10.2743 7.84489 10.2615 7.77567 10.2358C7.70644 10.2103 7.64061 10.1663 7.57817 10.1038L5.50517 8.03083C5.40606 7.93161 5.35711 7.81556 5.35833 7.68267C5.35967 7.54978 5.40861 7.43161 5.50517 7.32817C5.60861 7.22483 5.72739 7.17144 5.8615 7.168C5.99572 7.16456 6.11456 7.21456 6.218 7.318L7.5 8.6V3.5C7.5 3.35811 7.54789 3.23933 7.64367 3.14367C7.73933 3.04789 7.85811 3 8 3C8.14189 3 8.26067 3.04789 8.35633 3.14367C8.45211 3.23933 8.5 3.35811 8.5 3.5V8.6L9.782 7.318C9.88122 7.21889 9.99894 7.16994 10.1352 7.17117C10.2715 7.1725 10.3914 7.22483 10.4948 7.32817C10.5914 7.43161 10.6414 7.54872 10.6448 7.6795C10.6483 7.81028 10.5983 7.92739 10.4948 8.03083L8.42183 10.1038C8.35939 10.1663 8.29356 10.2103 8.22433 10.2358C8.15511 10.2615 8.08033 10.2743 8 10.2743ZM4.20517 13C3.86839 13 3.58333 12.8833 3.35 12.65C3.11667 12.4167 3 12.1316 3 11.7948V10.4872C3 10.3453 3.04789 10.2265 3.14367 10.1308C3.23933 10.0351 3.35811 9.98717 3.5 9.98717C3.64189 9.98717 3.76067 10.0351 3.85633 10.1308C3.95211 10.2265 4 10.3453 4 10.4872V11.7948C4 11.8462 4.02139 11.8932 4.06417 11.9358C4.10683 11.9786 4.15383 12 4.20517 12H11.7948C11.8462 12 11.8932 11.9786 11.9358 11.9358C11.9786 11.8932 12 11.8462 12 11.7948V10.4872C12 10.3453 12.0479 10.2265 12.1437 10.1308C12.2393 10.0351 12.3581 9.98717 12.5 9.98717C12.6419 9.98717 12.7607 10.0351 12.8563 10.1308C12.9521 10.2265 13 10.3453 13 10.4872V11.7948C13 12.1316 12.8833 12.4167 12.65 12.65C12.4167 12.8833 12.1316 13 11.7948 13H4.20517Z"
                                      fill="black"
                                    />
                                  </svg>
                                  <span>Download Question Document</span>
                                </div>
                                <div
                                  className={style.downloadButton}
                                  data-testid="download-button"
                                  onClick={() => handleDownloadClick(record.id, 'solution_doc')}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8 10.2743C7.91967 10.2743 7.84489 10.2615 7.77567 10.2358C7.70644 10.2103 7.64061 10.1663 7.57817 10.1038L5.50517 8.03083C5.40606 7.93161 5.35711 7.81556 5.35833 7.68267C5.35967 7.54978 5.40861 7.43161 5.50517 7.32817C5.60861 7.22483 5.72739 7.17144 5.8615 7.168C5.99572 7.16456 6.11456 7.21456 6.218 7.318L7.5 8.6V3.5C7.5 3.35811 7.54789 3.23933 7.64367 3.14367C7.73933 3.04789 7.85811 3 8 3C8.14189 3 8.26067 3.04789 8.35633 3.14367C8.45211 3.23933 8.5 3.35811 8.5 3.5V8.6L9.782 7.318C9.88122 7.21889 9.99894 7.16994 10.1352 7.17117C10.2715 7.1725 10.3914 7.22483 10.4948 7.32817C10.5914 7.43161 10.6414 7.54872 10.6448 7.6795C10.6483 7.81028 10.5983 7.92739 10.4948 8.03083L8.42183 10.1038C8.35939 10.1663 8.29356 10.2103 8.22433 10.2358C8.15511 10.2615 8.08033 10.2743 8 10.2743ZM4.20517 13C3.86839 13 3.58333 12.8833 3.35 12.65C3.11667 12.4167 3 12.1316 3 11.7948V10.4872C3 10.3453 3.04789 10.2265 3.14367 10.1308C3.23933 10.0351 3.35811 9.98717 3.5 9.98717C3.64189 9.98717 3.76067 10.0351 3.85633 10.1308C3.95211 10.2265 4 10.3453 4 10.4872V11.7948C4 11.8462 4.02139 11.8932 4.06417 11.9358C4.10683 11.9786 4.15383 12 4.20517 12H11.7948C11.8462 12 11.8932 11.9786 11.9358 11.9358C11.9786 11.8932 12 11.8462 12 11.7948V10.4872C12 10.3453 12.0479 10.2265 12.1437 10.1308C12.2393 10.0351 12.3581 9.98717 12.5 9.98717C12.6419 9.98717 12.7607 10.0351 12.8563 10.1308C12.9521 10.2265 13 10.3453 13 10.4872V11.7948C13 12.1316 12.8833 12.4167 12.65 12.65C12.4167 12.8833 12.1316 13 11.7948 13H4.20517Z"
                                      fill="black"
                                    />
                                  </svg>
                                  <span>Download Solution Document</span>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className={style.noData}>
                <div className={style.noDataText}>There are no records to display</div>
              </div>
            )}
          </div>
          {/*______________________________ PAGINATION ______________________________  */}
          {totalCount !== 0 && (
            <div className={style.pagination}>
              <div className={style.number}>
                The page you’re on &nbsp; &nbsp; <button className={style.currentpage}> {currentPage} </button>
              </div>
              <div className={style.line}>
                <svg width="2" height="24" viewBox="0 0 2 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line
                    opacity="0.1"
                    x1="0.695312"
                    y1="23.5"
                    x2="0.695311"
                    y2="0.5"
                    stroke="#764FDB"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className={style.change}>
                <div className={style.changeButton}>
                  <button
                    className={style.prevButton}
                    data-testid="prev-button"
                    onClick={handlePrevPage}
                    disabled={prevUrl == null}
                  ></button>
                </div>
                <div className={style.current}>
                  {offset + 1}-{last} of {totalCount}
                </div>
                <div className={style.changeButton}>
                  <button
                    data-testid="next-button"
                    className={style.nextButton}
                    onClick={handleNextPage}
                    disabled={nextUrl == null}
                  ></button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <LockModal
        modalData={modalModalData}
        forwardButton={() => {
          navigate('/import-questions', {
            state: navigationPayload, // passing name and doc_id from the row
          });
        }}
        modalMedium={modalMedium}
        closeModalMedium={closeModalMedium}
      />
    </AuthLayout>
  );
};

export default DocumentLogger;
