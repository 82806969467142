import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import style from '../../../../../../assets/styles/createQuestion.module.css';
import QuillEditor from '../../../../../atoms/QuillEditor';

interface MCQSingleAnswerProps {
  body: any[];
  questionbutton: number;
  setbodyformultiquestion: (body: any[]) => void;
}

const MCQ_singleAnswer: React.FC<MCQSingleAnswerProps> = ({ body, questionbutton, setbodyformultiquestion }) => {
  //_______ redux selector _______
  const selector = useSelector((state: any) => state.QuestionScreenReducer);
  const basicInfo = useSelector((state: any) => state.BasicInformationReducer);

  // state of explanation_text and compose text
  const [questionDirection, setQuestionDirection] = useState(body[questionbutton]?.question_short_identifier);
  const [explanation_text, setExplanation_text] = useState(body[questionbutton]?.explaination_text);
  const [compose_text, setCompose_text] = useState(body[questionbutton]?.compose_question);
  const [explanation_video, setExplanation_video] = useState(body[questionbutton]?.explaination_video);
  const [lastIndex, setlastIndex] = useState(body[questionbutton]?.last_option_idx);
  const [randomise_answers, setRandomise_answers] = useState(body[questionbutton]?.randomise_answers);
  const [videoId, setvideoId] = useState(body[questionbutton]?.video_id);

  // total number of answer
  const [answers, setAnswers] = useState(body[questionbutton]?.options || ['', '', '', '']);
  const [correntAnswer, setCorrentAnswer] = useState(body[questionbutton]?.correct_answer);

  useEffect(() => {
    if (Array.isArray(body[questionbutton]?.options) && body[questionbutton]?.options.length == 0) {
      setAnswers(['', '', '', '']);
    }
  }, [body[questionbutton]?.options]);

  const AnswerSet = (text: any, i: any) => {
    const answer = btoa(text);
    const answerinNumber = [...answers];
    answerinNumber[i] = answer;
    setAnswers(answerinNumber);
  };

  // correct answer for TITA
  const [correntAnswerTita, setCorrentAnswerTita] = useState([]);

  // function to add the multiple options
  function AddAnswerCall() {
    setAnswers((current: string[]) => [...current, '']);
  }

  // function to close the multiple options
  function CloseAnswerCall() {
    const newArray = [...answers];
    newArray.pop();
    setAnswers(newArray);
  }

  // encode tunymce to normal text
  // const decode = (item, key, index) => {
  //   if (item.length > 0) {
  //     // Your HTML content in a variable
  //     const htmlString = item;

  //     // Create a new DOMParser
  //     const parser = new DOMParser();

  //     // Parse the HTML string
  //     const parsedHtml = parser.parseFromString(htmlString, 'text/html');

  //     // Access the innerHTML property of the first child
  //     const innerHTML = parsedHtml.body.firstChild.innerHTML;

  //     // Store innerHTML in a variable
  //     const myVariable = innerHTML;

  //     // Now you have the innerHTML content (i.e., "helljhabdsaho") saved in the `myVariable` variable
  //     if (key === setAnswers) {
  //       const answerinNumber = [...answers];
  //       answerinNumber[index] = myVariable;
  //       key(answerinNumber);
  //     } else {
  //       key(myVariable);
  //     }
  //   } else {
  //   }
  // };

  const AddUpdateBody = () => {
    const updatebody = [...body];
    updatebody[questionbutton] = {
      idx: questionbutton,
      question_type_id: body[questionbutton]?.question_type_id,
      question_short_identifier: questionDirection,
      compose_question: compose_text,
      options: answers,
      correct_answer: correntAnswer,
      last_option_idx: lastIndex,
      randomise_answers: randomise_answers,
      explaination_video: explanation_video || null,
      explaination_text: explanation_text || null,
      task_label: null,
      video_id: videoId || null,
      // "difficulty":basicInfo.difficulty,
      // "question_type":body[questionbutton]?.question_type_id,
      // "format":basicInfo.format
    };
    setbodyformultiquestion(updatebody);
  };

  useEffect(() => {
    AddUpdateBody();
  }, [
    questionDirection,
    compose_text,
    explanation_text,
    answers,
    correntAnswer,
    lastIndex,
    randomise_answers,
    explanation_video,
    videoId,
  ]);

  function b64DecodeUnicode(str: string) {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }
  return (
    <>
      {/* QUESTION_CREATING_FINAL_SCREEN  */}
      <div className={style.tinymce_text}>
        {/* add explanation_text with tnymce*/}
        <div className={style.Question_Direction}>
          <p>
            Enter Question Short Identifier <b className={style.red_mendantory}>*</b>
          </p>
          <textarea
            //type="text"
            placeholder="Enter Question Short Identifier"
            className={style.question_identifier}
            value={questionDirection}
            onChange={(e) => setQuestionDirection(e.target.value)}
          />
          {/* <Editor
                        value={questionDirection}
                        onEditorChange={(newText, editor) => {
                            setQuestionDirection(editor.getContent({ format: 'text' }))
                        }}
                    /> */}
        </div>

        {/* add compose_text with tnymce*/}
        <div className={style.compose_text}>
          <p>
            Compose Question <b className={style.red_mendantory}>*</b>
          </p>
          {/* <Editor
                        apiKey='43r1aznok44zxafnhlerjdhremu6cizk6ropi635y4we48qz'
                        value={compose_text != null && b64DecodeUnicode(compose_text)}
                        onEditorChange={(newText, editor) => {
                            setCompose_text(btoa(editor.getContent({ format: 'html' })))
                        }}

                    /> */}

          <div style={{ height: 'auto', flexGrow: '1' }}>
            <QuillEditor
              defaultValue={compose_text != null ? b64DecodeUnicode(compose_text) : ''}
              onChange={(text) => setCompose_text(btoa(text))}
            />
          </div>
        </div>

        {/* add enter-answer with tnymce*/}
        <div className={style.enter_answer}>
          <div className={style.title_detail}>
            <p className={style.title_enter_answer}> Enter Answers </p>
            <p className={style.title_enter_desc}> After providing answers, select the correct one</p>
          </div>
          <div className={style.random_text}>
            <label className={style.title_enter_answer}> Randomise Answer </label>
            <input
              type="checkbox"
              className={style.pointer}
              onClick={(e: any) => setRandomise_answers(e.target.checked ? true : false)}
            />
          </div>
        </div>

        {/* add Answer_text with tnymce*/}
        {answers?.map((items: any, i: any) => (
          <>
            <div className={style.Answer_text}>
              <p>
                <span>Answer {i + 1}</span>

                <span className={style.select}>
                  {' '}
                  &nbsp; &nbsp; &nbsp; Select to make as Correct Answer &nbsp;{' '}
                  <input
                    type="checkbox"
                    className={style.pointer}
                    onClick={() => setCorrentAnswer(i)}
                    checked={i == correntAnswer}
                  />
                </span>
                {i === answers?.length - 1 ? (
                  <span className={style.select}>
                    Set as Last Option &nbsp;{' '}
                    <input
                      type="checkbox"
                      className={style.pointer}
                      onClick={(e: any) => setlastIndex(e.target.checked ? answers?.length : null)}
                    />
                  </span>
                ) : (
                  ''
                )}
              </p>
              <div className={style.editor_box}>
                <div className={style.editor}>
                  {/* <Editor
                                        apiKey='43r1aznok44zxafnhlerjdhremu6cizk6ropi635y4we48qz'
                                        value={b64DecodeUnicode(answers[i])}
                                        onEditorChange={(newText, editor) => AnswerSet(editor, i)}
                                    /> */}

                  <div style={{ height: 'auto', flexGrow: '1' }}>
                    <QuillEditor defaultValue={b64DecodeUnicode(answers[i])} onChange={(text) => AnswerSet(text, i)} />
                  </div>
                </div>

                {i === 0 || i === 1 ? (
                  ''
                ) : (
                  <div className={` ${style.close_answer} ${style.pointer}`} onClick={() => CloseAnswerCall()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18.8504 9.14062L18.2004 19.2106C18.0904 20.7806 18.0004 22.0006 15.2104 22.0006H8.79039C6.00039 22.0006 5.91039 20.7806 5.80039 19.2106L5.15039 9.14062"
                        stroke="#272727"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047"
                        stroke="#272727"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                        stroke="#272727"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span>Remove</span>
                  </div>
                )}
              </div>
            </div>
          </>
        ))}

        {/* Add answer button: to add more answer  */}
        <div className={` ${style.Add_answer} ${style.pointer}`} onClick={() => AddAnswerCall()}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
              stroke="#00ABFB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M7.5 12H16.5" stroke="#00ABFB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 16.5V7.5" stroke="#00ABFB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span>Add Answer</span>
        </div>

        {/* explanation line  */}
        <div className={style.explanation_line}>
          <hr className={style.line} />
        </div>

        {/* add explanation_video*/}
        <div className={style.explanation_text}>
          <p>
            Add Explanation Video <b className={style.red_mendantory}>*</b>
          </p>
          <div className={style.explanation_video}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.17188 14.8287L14.8287 9.17188"
                stroke="#272727"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.05063 11.293L5.63642 12.7072C4.07432 14.2693 4.07432 16.8019 5.63642 18.364C7.19851 19.9261 9.73117 19.9261 11.2933 18.364L12.7075 16.9498"
                stroke="#272727"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.293 7.05063L12.7072 5.63642C14.2693 4.07432 16.8019 4.07432 18.364 5.63642C19.9261 7.19851 19.9261 9.73117 18.364 11.2933L16.9498 12.7075"
                stroke="#272727"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input
              type="text"
              value={explanation_video}
              placeholder="Attach Video Video Link"
              onChange={(e) => setExplanation_video(e.target.value)}
            />
          </div>
        </div>

        {/* add explanation_text with tnymce*/}
        <div className={style.explanation_text}>
          <p>
            Add Explanation Text <b className={style.red_mendantory}>*</b>
          </p>
          {/* <Editor
                        apiKey='43r1aznok44zxafnhlerjdhremu6cizk6ropi635y4we48qz'
                        value={explanation_text != null && b64DecodeUnicode(explanation_text)}
                        onEditorChange={(newText, editor) => {
                            setExplanation_text(btoa(editor.getContent({ format: 'html' })))
                        }}
                    /> */}

          <div style={{ height: 'auto', flexGrow: '1' }}>
            <QuillEditor
              defaultValue={explanation_text != null ? b64DecodeUnicode(explanation_text) : ''}
              onChange={(text) => setExplanation_text(btoa(text))}
            />
          </div>
        </div>

        <div className={style.explanation_text}>
          <p>
            Video Id <b className={style.red_mendantory}>*</b>
          </p>
          <div className={style.explanation_video}>
            <input
              type="text"
              value={videoId}
              placeholder="Video Cipher ID"
              onChange={(e) => setvideoId(e.target.value)}
            />
          </div>
        </div>
      </div>
      {/* QUESTION_CREATING_FINAL_SCREEN  */}
    </>
  );
};

export default MCQ_singleAnswer;
