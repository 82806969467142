import React from 'react';
import style from '../../../../assets/styles/rightPanelStyle.module.css';
import { MatserScreenChange } from '../../../../redux/action';
import { useDispatch } from 'react-redux';

interface HeaderProps {
  questionBank: { id: number; name: string }[];
  ReportData: { id: number; name: string }[];
  setQuestionBankId: (id: number) => void;
  title: string;
  MasterData: { id: number; name: string }[];
}

const Header: React.FC<HeaderProps> = ({ questionBank, ReportData, setQuestionBankId, title, MasterData }) => {
  const dispatch = useDispatch();
  return (
    <div className={style.header_content}>
      {/* __________ THIS IS HEADER of top bar and data is dynamic here according to screen __________  */}

      <div className={style.header_text}>{title}</div>

      {/* right pannel header for getting the ID of question draft  */}
      {title === 'Question Bank' ? (
        <div className={style.header_drop}>
          <select
            name="question"
            id="question"
            onClick={(e) => setQuestionBankId(Number((e.target as HTMLSelectElement).value))}
          >
            {questionBank?.map((items, i) => (
              <option key={i} value={items.id}>
                {items.name}
              </option>
            ))}
          </select>
        </div>
      ) : (
        ''
      )}

      {title === 'Masters' ? (
        <div className={style.header_drop}>
          <select
            name="question"
            id="question"
            onClick={(e) => dispatch(MatserScreenChange(Number((e.target as HTMLSelectElement).value)))}
          >
            {MasterData?.map((items, i) => (
              <option key={i} value={items.id}>
                {items.name}
              </option>
            ))}
          </select>
        </div>
      ) : (
        ''
      )}

      {title === 'Reports' ? (
        <div className={style.header_drop}>
          <select
            name="question"
            id="question"
            onClick={(e) => setQuestionBankId(Number((e.target as HTMLSelectElement).value))}
          >
            {ReportData?.map((items, i) => (
              <option key={i} value={items.id}>
                {items.name}
              </option>
            ))}
          </select>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Header;
