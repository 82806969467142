import React, { useEffect, useState, useTransition } from 'react';
import BasicSettings from './BasicSettings';
import AdvanceSettings from './AdvanceSettings';
import Sections from './Sections';
import style from '../../../../../assets/styles/testCreationStyle.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { changeAdd_test_screen } from '../../../../../redux/action';
import ConfirmModal from '../../../../atoms/Modal/ConfirmModal';

const AddTest = () => {
  // _________which one to show my or all _________

  const { addtestscreen } = useSelector((state) => state.GetAllData);
  const dispatch = useDispatch();

  const ChangeAddTestScreen = (e) => {
    dispatch(changeAdd_test_screen(e));
  };

  const [publish, setPublish] = useState(false);

  // _________ publish button status _________
  const [buttonStatus, setButtonStatus] = useState(false);

  const [pdfresult, setpdfResult] = useState(null);

  useEffect(() => {
    if (pdfresult != null) {
      setShowTooltip(true);

      const timeoutId = setTimeout(() => {
        setShowTooltip(false);
      }, 2000); // 2 seconds

      return () => clearTimeout(timeoutId); // Clear the timeout when the component unmounts or when pdfresult changes
    }
  }, [pdfresult]);

  const downloadFile = (e) => {
    window.open(e, '_blank', 'pdf_download');
  };

  const [showTooltip, setShowTooltip] = useState(false);

  // _________________ confirmation _________________

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isConfirmed) {
        event.preventDefault();
        event.returnValue = '';
        setIsModalOpen(true);
        return '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isConfirmed]);

  // const handleConfirm = () => {
  //   setIsConfirmed(true);
  //   setIsModalOpen(false);
  //   window.removeEventListener('beforeunload', () => { });
  //   window.close(); // or any other logic to allow the page to close
  // };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  // _________________ confirmation _________________

  return (
    <div>
      <div className={style.test_creation_form}>
        <div className={style.testContainer}>
          {/* ______________ top section of test add ______________ */}
          <div className={style.test_top_content_table}>
            <div className={style.table_title}>
              <span
                className={addtestscreen ? ` ${style.pointer}` : ` ${style.underline_active} ${style.pointer}`}
                // onClick={() => ChangeAddTestScreen(0)}
              >
                Basic Settings
              </span>
              <span
                className={addtestscreen === 1 ? ` ${style.underline_active} ${style.pointer}` : ` ${style.pointer}`}
                // onClick={() => ChangeAddTestScreen(1)}
              >
                Sections
              </span>

              <span
                className={addtestscreen === 2 ? ` ${style.underline_active} ${style.pointer}` : ` ${style.pointer}`}
                // onClick={() => ChangeAddTestScreen(2)}
              >
                Advance Settings
              </span>
            </div>
            <div className={style.table_title_right_options}>
              {/* <span className={style.saveDraft}>Save draft</span> */}
              <div className={style.iconBox}>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.9196 7.6C17.8996 2.91 14.0996 0 9.99958 0C5.89958 0 2.09958 2.91 0.0795785 7.6C0.0245152 7.72617 -0.00390625 7.86234 -0.00390625 8C-0.00390625 8.13766 0.0245152 8.27383 0.0795785 8.4C2.09958 13.09 5.89958 16 9.99958 16C14.0996 16 17.8996 13.09 19.9196 8.4C19.9746 8.27383 20.0031 8.13766 20.0031 8C20.0031 7.86234 19.9746 7.72617 19.9196 7.6ZM9.99958 14C6.82958 14 3.82958 11.71 2.09958 8C3.82958 4.29 6.82958 2 9.99958 2C13.1696 2 16.1696 4.29 17.8996 8C16.1696 11.71 13.1696 14 9.99958 14ZM9.99958 4C9.20845 4 8.43509 4.2346 7.7773 4.67412C7.1195 5.11365 6.60681 5.73836 6.30406 6.46927C6.00131 7.20017 5.9221 8.00444 6.07644 8.78036C6.23078 9.55628 6.61174 10.269 7.17115 10.8284C7.73056 11.3878 8.44329 11.7688 9.21922 11.9231C9.99514 12.0775 10.7994 11.9983 11.5303 11.6955C12.2612 11.3928 12.8859 10.8801 13.3255 10.2223C13.765 9.56448 13.9996 8.79113 13.9996 8C13.9996 6.93913 13.5782 5.92172 12.828 5.17157C12.0779 4.42143 11.0604 4 9.99958 4ZM9.99958 10C9.60402 10 9.21734 9.8827 8.88844 9.66294C8.55954 9.44318 8.30319 9.13082 8.15182 8.76537C8.00044 8.39991 7.96084 7.99778 8.03801 7.60982C8.11518 7.22186 8.30566 6.86549 8.58537 6.58579C8.86507 6.30608 9.22144 6.1156 9.6094 6.03843C9.99736 5.96126 10.3995 6.00087 10.7649 6.15224C11.1304 6.30362 11.4428 6.55996 11.6625 6.88886C11.8823 7.21776 11.9996 7.60444 11.9996 8C11.9996 8.53043 11.7889 9.03914 11.4138 9.41421C11.0387 9.78929 10.53 10 9.99958 10Z"
                    fill="#212529"
                  />
                </svg>
              </div>

              <div
                className={style.iconBox}
                onClick={() => downloadFile(pdfresult)}
                style={{ background: pdfresult != null ? '#00abfb' : '' }}
              >
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.1211 15.8939V4.06055"
                    stroke="black"
                    strokeWidth="1.8"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.0352 13.0156L12.1192 15.8931L9.20316 13.0156"
                    stroke="black"
                    strokeWidth="1.8"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.7541 8.71289H17.6871C18.171 8.71289 18.6501 8.80657 19.0971 8.98858C19.5442 9.17059 19.9503 9.43736 20.2924 9.77365C20.6345 10.1099 20.9059 10.5092 21.091 10.9486C21.276 11.3879 21.3712 11.8588 21.3711 12.3344V17.1341C21.3711 18.092 20.9839 19.0107 20.2947 19.688C19.6055 20.3653 18.6708 20.7458 17.6961 20.7458H6.55609C5.57877 20.7458 4.64148 20.3642 3.95041 19.6851C3.25933 19.0059 2.87109 18.0848 2.87109 17.1243V12.3235C2.87136 11.3659 3.25866 10.4475 3.94783 9.77037C4.637 9.09327 5.5716 8.71289 6.54609 8.71289H7.48809"
                    stroke="black"
                    strokeWidth="1.8"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className={style.tooltip_container}>
                {showTooltip && <div className={style.tooltip}>You can download Questions PDF File</div>}
              </div>
              <button
                className={buttonStatus ? style.publishTestButtonOn : style.publishTestButton}
                disabled={addtestscreen == 0 || addtestscreen == 1}
                onClick={() => setPublish(true)}
              >
                Save Test
              </button>
            </div>
          </div>

          {/* ______________ screen section of test add ______________ */}
          {addtestscreen == 0 ? <BasicSettings /> : ''}
          {addtestscreen == 1 ? <Sections /> : ''}
          {addtestscreen == 2 ? (
            <AdvanceSettings
              publish={publish}
              setButtonStatus={setButtonStatus}
              setpdfResult={setpdfResult}
              pdfresult={pdfresult}
            />
          ) : (
            ''
          )}
        </div>
      </div>

      {/* <ConfirmModal isOpen={isModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} /> */}
    </div>
  );
};

export default AddTest;
