import { useEffect, useRef, useState } from 'react';

import style from '../../../../../assets/styles/testCreationStyle.module.css';
import { CallWithAuth } from '../../../../../action/apiActions';
import { GET_FREE_TAG } from '../../../../../action/apiPath';
import { useDispatch, useSelector } from 'react-redux';
import {
  Add_Basic_Setting_Test,
  Get_All_Category,
  Get_Product_Type,
  Task_of_User,
  change_test_screen,
} from '../../../../../redux/action';
import QuillEditor from '../../../../atoms/QuillEditor';

const BasicSettings = ({ MasterData }) => {
  const rowRef = useRef();

  const dispatch = useDispatch();
  const { categoryList } = useSelector((state) => state.GetAllData);
  const { getProductType } = useSelector((state) => state.GetAllData);

  useEffect(() => {
    dispatch(Get_All_Category('', ''));
    dispatch(Task_of_User(0));
    dispatch(Get_Product_Type());
  }, []);

  //_______________________________________________ Creation of new test _________________________
  const [testName, setTestName] = useState('');
  const [noofQuestiontoPass, setnoofQuestiontoPass] = useState(null);
  const [rendering_template, setrendering_template] = useState(null);
  const [academicYear, setAcademicYear] = useState(null);
  const [category, setCategory] = useState(null);
  const [multiselectedfreeTag, setmultiSelectedfreeTag] = useState([]);
  const [newFreeTag, setnewFreeTag] = useState([]);
  const [markingScheme, setMarkingScheme] = useState(0);
  const [correctAnswerMarks, setCorrectAnswerMarks] = useState(null);
  const [incorrectAnswerMarks, setIncorrectAnswerMarks] = useState(null);
  const [unattemptedPenalty, setUnattemptedPenalty] = useState(null);
  const [unattempted_penalty_starts_from, setunattempted_penalty_starts_from] = useState(null);
  const [instruction, setInstruction] = useState([
    {
      has_timer: true,
      minutes: null,
      seconds: null,
      instruction_text: '',
    },
  ]);

  var body = {
    test_type: 0,
    rendering_template: rendering_template,
    test_name: testName,
    academic_year: academicYear,
    category: category,
    free_tags: multiselectedfreeTag?.map((item) => item.id),
    new_free_tags: newFreeTag,
    number_of_questions_to_pass: noofQuestiontoPass,
    // task_label: testLabel,
    marking_scheme: markingScheme,
    correct_answer_marks: correctAnswerMarks,
    incorrect_answer_marks: incorrectAnswerMarks,
    unattempted_penalty: unattemptedPenalty,
    unattempted_penalty_starts_from: unattempted_penalty_starts_from,
    marking_logic_level: 0,
    instructions: instruction,
  };

  //_______________ Fn for handle test type
  const handleRenderingTemplate = (e) => {
    const selectedTemplate = Number(e.target.value);
    setrendering_template(selectedTemplate);
  };

  //_______________ Fn for handle test name
  const handleTestName = (e) => {
    const selectedName = e.target.value;
    setTestName(selectedName);
  };

  const handleNumberOfQuestionToPass = (e) => {
    const selectedNumber = Number(e.target.value);
    setnoofQuestiontoPass(selectedNumber);
  };

  //_______________ Fn for handle academic year
  const handleAcademicYear = (e) => {
    const selectedYear = Number(e.target.value);
    setAcademicYear(selectedYear);
  };

  //_______________ Fn for handle category
  const handleCategory = (e) => {
    const selectedCategory = Number(e.target.value);
    setCategory(selectedCategory);
  };

  //_______________ Fn for handle free tags

  // ________________ Initialization of FREE TAG ________________//
  const [multifreeTaginput, setmultifreeTaginput] = useState('');
  const [multifreeTag, setmultifreeTag] = useState([]);
  const [multifreeTagStatus, setmultifreeTagStatus] = useState(false);
  const [newfreeTags, setnewfreeTags] = useState([]);
  const [newfreeTagStatus, setnewfreeTagStatus] = useState(false);

  // CALL GET API FOR getting TAGS
  const TagFreeMultiple = async (e) => {
    setmultifreeTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_FREE_TAG + '?keyword=' + e);
      if (data.status && data.res.status === 200) {
        if (data.res.data.length > 0) {
          setmultifreeTag(data.res.data);
          setnewfreeTagStatus(false);
          setmultifreeTagStatus(true);
        } else {
          setnewfreeTags([e]);
          setmultifreeTagStatus(false);
          setnewfreeTagStatus(true);
        }
      }
    } else {
      setmultifreeTagStatus(false);
    }
  };

  // select tags from options
  const selectFreeTag = (e) => {
    setmultiSelectedfreeTag((current) => [...current, e]);
    setmultifreeTagStatus(false);
    setmultifreeTaginput('');
  };

  const selectnewFreeTag = (e) => {
    setnewFreeTag((current) => [...current, e]);
    setnewfreeTagStatus(false);
    setmultifreeTaginput('');
  };

  const CancelButton = (e) => {
    e.preventDefault();
    setnewFreeTag([]);
    setmultiSelectedfreeTag([]);
  };
  //_______________ Fn for handle free tags over }

  //_______________ Fn for handle marking schemes
  const handleMarkingScheme = (e) => {
    const selectedMarking = Number(e.target.value);
    setMarkingScheme(selectedMarking);
  };

  //_______________ Fn for handle input markings
  const handleInputChange = (e, setter) => {
    const value = e.target.value;
    setter(Number(value));
  };

  //________________Fn for handle Intructions

  const handleTimerChange = (event, type, index) => {
    let value;
    if (type === 'has_timer') {
      value = event.target.checked;
    } else {
      value = parseInt(event.target.value, 10);
    }
    setInstruction((prevState) => {
      const newState = [...prevState];
      newState[index][type] = value;
      return newState;
    });
  };

  const handleInstructionTextChange = (content, index, event) => {
    const answer = btoa(unescape(encodeURIComponent(content)));
    setInstruction((prevState) => {
      const newState = [...prevState];
      newState[index].instruction_text = answer;
      return newState;
    });
  };

  const addScreen = () => {
    setInstruction((prevState) => [
      ...prevState,
      {
        has_timer: true,
        minutes: null,
        seconds: null,
        instruction_text: '',
      },
    ]);
  };

  const removeScreen = (index) => {
    setInstruction((prevState) => prevState.filter((_, i) => i !== index));
  };

  //_______________________________________ Instructions exit ________________________________//

  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2011;
    const endYear = currentYear + 5;
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const decodeBase64 = (base64String) => {
    return b64DecodeUnicode(base64String);
  };

  function b64DecodeUnicode(str) {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  return (
    <div className={style.masterTestCreation}>
      <div className={style.testCreationArea}>
        <div className={style.bound}>
          <div className={style.templateDrop}>
            <div className={style.templateDrop_item}>
              <div className={style.templateGroup}>
                <span>Test Paper Rendering Template </span>
                <b className={style.red_mendantory}>*</b>
                <select
                  name="question"
                  id="question"
                  className={style.template_drop_filter}
                  onClick={handleRenderingTemplate}
                >
                  <option>----- Select Rendering Template -----</option>
                  {getProductType?.map((items, i) => (
                    <option value={items.id}>{items.product_type}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Test Name */}
          <div className={style.templateDrop}>
            <div className={style.templateDrop_item}>
              <div className={style.templateGroup}>
                <span>Test Names </span>
                <b className={style.red_mendantory}>*</b>
                <input onChange={handleTestName} placeholder="" className={style.template_drop_filter} />
              </div>
            </div>
          </div>
        </div>

        <div className={style.bound}>
          <div className={style.templateDrop}>
            <div className={style.templateDrop_item}>
              <div className={style.templateGroup}>
                <span>Academic Year </span>
                <b className={style.red_mendantory}>*</b>
                <select
                  name="question"
                  id="question"
                  className={style.template_drop_filter}
                  onClick={handleAcademicYear}
                >
                  <option>----- Select Academic Year -----</option>
                  {generateYears().map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className={style.templateDrop}>
            <div className={style.templateDrop_item}>
              <div className={style.templateGroup}>
                <span>Category </span>
                <b className={style.red_mendantory}>*</b>
                <select name="question" id="question" className={style.template_drop_filter} onClick={handleCategory}>
                  <option value={null}>----- Select Category -----</option>
                  {categoryList?.map((items, i) => (
                    <option value={items.id}>{items.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className={style.templateDrop}>
          <div className={style.templateDrop_item}>
            <div className={style.templateGroup}>
              <span>Free Form Tags </span>
              <b className={style.red_mendantory}>*</b>
              <div className={style.search_listing}>
                <div className={style.tag_icon}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                      stroke="#212529"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M18.2598 19.2592L16.1406 16.8594"
                      stroke="#212529"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <span>Tags</span>
                </div>
                {/* this is input field for searching */}
                <div className={style.input_search} ref={rowRef}>
                  {/* multifreeTag selected one  */}
                  {multiselectedfreeTag?.map((items, i) => (
                    <>
                      <span className={style.selected_tags}>{items.name} </span>
                    </>
                  ))}

                  {/* new free tag selected one  */}
                  {newFreeTag?.map((items, i) => (
                    <>
                      <span className={style.selected_tags}>{items} </span>
                    </>
                  ))}

                  {/* input field  */}
                  <input
                    type="search"
                    id="searchInput"
                    placeholder=""
                    value={multifreeTaginput}
                    onChange={(e) => TagFreeMultiple(e.target.value)}
                  />

                  {/* list of items of pre-defined multifree tag  */}
                  {multifreeTagStatus ? (
                    <div className={style.list_input_primary}>
                      {multifreeTag?.map((items, i) => (
                        <>
                          <div className={style.pointer} onClick={() => selectFreeTag(items)}>
                            <span>{items.name} </span>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}

                  {/* list of item of user-defined multifreetag  */}
                  {newfreeTagStatus ? (
                    <div className={style.list_input_primary}>
                      {newfreeTags?.map((items, i) => (
                        <>
                          <div className={style.pointer} onClick={() => selectnewFreeTag(items)}>
                            <span>{items} </span>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {/* cancel button  */}
                <div className={style.cancel_button}>
                  <button className={style.pointer} onClick={(e) => CancelButton(e)} type="cancel">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.75781 7.75781L16.2431 16.2431"
                        stroke="#272727"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.75691 16.2431L16.2422 7.75781"
                        stroke="#272727"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {rendering_template === 2 && (
          <>
            <div className={style.templateDrop}>
              <div className={style.templateDrop_item}>
                <div className={style.templateGroup}>
                  <span>Number of Question to Pass </span>
                  <b className={style.red_mendantory}>*</b>
                  <input
                    type="number"
                    min="0"
                    step="1"
                    value={noofQuestiontoPass}
                    onChange={handleNumberOfQuestionToPass}
                    placeholder=""
                    className={style.template_drop_filter}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div className={style.templateDrop}>
          <hr className={style.hrLine} />
          <div className={style.pageTwoContainer}>
            <h3 className={style.testMarklabel}>Group Marking Details</h3>
            <div className={style.templateDrop}>
              <div className={style.templateDrop_item}>
                <div className={style.templateGroup}>
                  <span>Marking Scheme </span>
                  <b className={style.red_mendantory}>*</b>
                  <select
                    name="question"
                    id="question"
                    className={style.template_drop_filter}
                    onClick={handleMarkingScheme}
                  >
                    <option value={0}>Regular -Per Question</option>
                    {/* <option value={1}>Progressive - Across Section</option>
                                        <option value={2}>Progressive - Section-wise</option> */}
                  </select>
                </div>
              </div>
            </div>

            <p style={{ paddingTop: '10px', paddingBottom: '10px' }} className={style.info}>
              All questions will follow this marking logic. Marking logic can be overwritten at a question level on next
              screen.
            </p>

            <div className={style.bound}>
              <div className={style.templateDrop}>
                <div className={style.templateDrop_item}>
                  <div className={style.templateGroup}>
                    <span>Correct Answer Marks </span>
                    <b className={style.red_mendantory}>*</b>
                    <input
                      type="number"
                      name="question"
                      id="question"
                      className={style.score_drop_filter}
                      placeholder="Input +/- score"
                      value={correctAnswerMarks}
                      onChange={(e) => handleInputChange(e, setCorrectAnswerMarks)}
                    />
                  </div>
                </div>
              </div>

              <div className={style.templateDrop}>
                <div className={style.templateDrop_item}>
                  <div className={style.templateGroup}>
                    <span>Incorrect Answer Marks </span>
                    <b className={style.red_mendantory}>*</b>
                    <input
                      type="number"
                      name="question"
                      id="question"
                      className={style.score_drop_filter}
                      placeholder="Input +/- score"
                      value={incorrectAnswerMarks}
                      onChange={(e) => handleInputChange(e, setIncorrectAnswerMarks)}
                    />
                  </div>
                </div>
              </div>

              {/* Test type */}
              {rendering_template === 5 && (
                <>
                  <div className={style.templateDrop}>
                    <div className={style.templateDrop_item}>
                      <div className={style.templateGroup}>
                        <span>Unattempted Penalty </span>
                        <b className={style.red_mendantory}>*</b>
                        <input
                          type="number"
                          name="question"
                          id="question"
                          className={style.score_drop_filter}
                          placeholder="Input +/- score"
                          value={unattemptedPenalty}
                          onChange={(e) => handleInputChange(e, setUnattemptedPenalty)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={style.templateDrop}>
                    <div className={style.templateDrop_item}>
                      <div className={style.templateGroup}>
                        <span>Unattempted Penalty Start From </span>
                        <b className={style.red_mendantory}>*</b>
                        <input
                          type="number"
                          name="question"
                          id="question"
                          className={style.score_drop_filter}
                          placeholder="Input +/- score"
                          value={unattempted_penalty_starts_from}
                          onChange={(e) => handleInputChange(e, setunattempted_penalty_starts_from)}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <hr className={style.hrLine} />

            <h3 className={style.testMarklabel}>Test Instructions</h3>
            <p className={style.info}>Add instructions to appear before the test commences</p>
            {instruction?.map((instruction, index) => (
              <div key={index} className={style.screenNum}>
                <p style={{ fontWeight: 500 }}>Screen {index + 1}</p>
                <div className={style.icon_text}>
                  <input
                    type="checkbox"
                    checked={Boolean(instruction.has_timer)}
                    onChange={(e) => handleTimerChange(e, 'has_timer', index)}
                  />
                  {/* <label>Has Timer</label> */}

                  <p className={style.info}>Instruction Screen has a timer</p>
                </div>
                <div className={style.timer}>
                  <input
                    className={style.timer_input}
                    type="number"
                    name="minutes"
                    min="0"
                    max="100"
                    step="1"
                    value={instruction.minutes}
                    onChange={(e) => handleTimerChange(e, 'minutes', index)}
                  />
                  <label htmlFor="minutes">Minutes</label>

                  <input
                    className={style.timer_input}
                    type="number"
                    name="seconds"
                    min="0"
                    max="100"
                    step="1"
                    value={instruction.seconds}
                    onChange={(e) => handleTimerChange(e, 'seconds', index)}
                  />
                  <label htmlFor="seconds">Seconds</label>
                </div>

                <p className={style.marketLogic}>Test Instructions </p>
                <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
                  {' '}
                  {/* <textarea
                                        type="textArea"
                                        placeholder="Type here"
                                        className={style.typeArea}
                                        value={instruction.instruction_text}
                                        onChange={(e) => handleInstructionTextChange(e, index)}
                                    /> */}
                  {/* <Editor
                                        apiKey='43r1aznok44zxafnhlerjdhremu6cizk6ropi635y4we48qz'
                                        init={{
                                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker markdown',
                                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                        }}
                                        value={decodeBase64(instruction.instruction_text)}
                                        onEditorChange={(content) => handleInstructionTextChange(content, index)}
                                    /> */}
                  <div style={{ height: 'auto', flexGrow: '1' }}>
                    <QuillEditor
                      defaultValue={decodeBase64(instruction.instruction_text)}
                      onChange={(content) => handleInstructionTextChange(content, index)}
                    />
                  </div>
                  {index !== 0 && (
                    <button type="button" className={style.penaltyDelete} onClick={() => removeScreen(index)}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M18.8504 9.14062L18.2004 19.2106C18.0904 20.7806 18.0004 22.0006 15.2104 22.0006H8.79039C6.00039 22.0006 5.91039 20.7806 5.80039 19.2106L5.15039 9.14062"
                          stroke="#272727"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047"
                          stroke="#272727"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                          stroke="#272727"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            ))}
            <div onClick={() => addScreen()} className={style.threshold}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#00ABFB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M7.5 12H16.5" stroke="#00ABFB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16.5V7.5" stroke="#00ABFB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <h5>Add Screen</h5>
            </div>

            <div className={style.performBT}>
              {/* <button
                                className={` ${style.btOne} ${style.common}`}
                                onClick={() => dispatch(change_test_screen(0))}
                            >
                                Back
                            </button> */}
              <button
                className={` ${style.btTwo} ${style.common}`}
                onClick={() => dispatch(Add_Basic_Setting_Test(body))}
              >
                Save And Countinue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicSettings;
