import React from 'react';

export default function NavPills({ data, style }: { data: any; style: any }) {
  // Check if data exists and has items with valid subtopics
  const hasValidSubtopics = data && data.length > 0 && 
    data.some((items: any) => items.subtopic && items.subtopic.trim() !== '');
    const validSubtopics = data?.filter((items: any) => 
      items.subtopic && items.subtopic.trim() !== ''
    ) || [];
  return (
    <div 
      className={style.selected_tagsWrap}
      style={{ 
        display: hasValidSubtopics ? 'flex' : 'none', 
       flexWrap: validSubtopics.length > 4 ? 'wrap' : 'nowrap'
      }}
    >
      {data?.map((items: any, i: number) => (
          <span
            key={i}
            style={{ cursor: 'pointer' }}
            title={`${items.subject} | ${items.area} | ${items.topic} | ${items.subtopic}`}
            className={style.selected_tags}
          >
            {items.subtopic.slice(0, 10) + '...'}
          </span>
        )
      )}
    </div>
  );
}