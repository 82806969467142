import React, { useEffect, useRef, useState } from 'react';
import { CallWithAuth } from '../../../../../action/apiActions';
import { GET_FREE_TAG } from '../../../../../action/apiPath';
import style from '../../../../../assets/styles/createQuestion.module.css';

const FreeTag = ({
  multiselectedfreeTag,
  setmultiSelectedfreeTag,
  setnewFreeTag,
  newFreeTag,
  RemovefreeTag,
  questionbutton,
  free_id,
}: {
  multiselectedfreeTag: any[];
  setmultiSelectedfreeTag: React.Dispatch<React.SetStateAction<any[]>>;
  setnewFreeTag: React.Dispatch<React.SetStateAction<any[]>>;
  newFreeTag: any[];
  RemovefreeTag: () => void;
  questionbutton?: any;
  free_id: any[];
}) => {
  // _________ STATE INITIALIZATION _________
  const [multifreeTaginput, setmultifreeTaginput] = useState('');

  const [multifreeTag, setmultifreeTag] = useState([]);
  const [AllmultifreeTag, setAllmultifreeTag] = useState([]);

  const [multifreeTagStatus, setmultifreeTagStatus] = useState(false);

  const [newfreeTags, setnewfreeTags] = useState<string[]>([]);
  const [newfreeTagStatus, setnewfreeTagStatus] = useState(false);

  // CALL GET API FOR getting TAGS
  const TagFreeMultiple = async (e: any) => {
    setmultifreeTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_FREE_TAG + '?keyword=' + e);
      if (data?.status && data?.res && data?.res.status === 200) {
        if (data.res.data.length > 0) {
          setmultifreeTag(data.res.data);
          setnewfreeTagStatus(false);
          setmultifreeTagStatus(true);
        } else {
          setnewfreeTags([e]);
          setmultifreeTagStatus(false);
          setnewfreeTagStatus(true);
        }
      }
    } else {
      setmultifreeTagStatus(false);
    }
  };

  // select tags from options
  const selectFreeTag = (e: any) => {
    setmultiSelectedfreeTag((current) => [...current, e]);
    setmultifreeTagStatus(false);
    setmultifreeTaginput('');
  };

  const selectnewFreeTag = (e: any) => {
    setnewFreeTag((current) => [...current, e]);
    setnewfreeTagStatus(false);
    setmultifreeTaginput('');
  };

  const CancelButton = () => {
    setnewFreeTag([]);
    setmultiSelectedfreeTag([]);
    RemovefreeTag();
  };

  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowClick = (event: any) => {
    if (rowRef.current && !rowRef.current.contains(event.target)) {
      // Check if the click is within the action buttons
      const isActionButtonClick = event.target.closest('.action_buttons');

      if (!isActionButtonClick) {
        setnewfreeTagStatus(false);
        setmultifreeTagStatus(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleRowClick);

    return () => {
      document.removeEventListener('mousedown', handleRowClick);
    };
  }, [handleRowClick]);

  useEffect(() => {
    if (free_id?.length == 0) {
      setmultiSelectedfreeTag([]);
    } else {
      const primaryID = AllmultifreeTag?.filter((item: any) => free_id?.includes(item.id)).map(
        (items: any, i) => items
      );
      setmultiSelectedfreeTag(primaryID);
    }
  }, [JSON.stringify(free_id), questionbutton, AllmultifreeTag]);

  const AllTagFreeMultiple = async () => {
    const data = await CallWithAuth('GET', GET_FREE_TAG);

    if (data.status && data.res && data.res.status === 200) {
      if (data.res.data.length > 0) {
        setAllmultifreeTag(data.res.data);
      }
    }
  };

  useEffect(() => {
    AllTagFreeMultiple();
  }, [questionbutton]);

  return (
    <>
      <div className={style.free_tag}>
        <div className={style.tag}>
          <div className={style.tag_title}>
            Free Tags
            {/* <b className={style.red_mendantory}>*</b> */}
          </div>

          <div className={style.tag_desc}>Name</div>

          <div className={style.tag_search}>
            <div className={style.tag_icon}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                  stroke="#212529"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.2598 19.2592L16.1406 16.8594"
                  stroke="#212529"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Tags</span>
            </div>

            {/* this is input field for searching */}
            <div className={style.input_search} ref={rowRef}>
              {/* multifreeTag selected one  */}
              <div style={{display: 'flex', flexWrap: 'wrap',  gap: '5px'}}>
              {multiselectedfreeTag?.map((items, i) => (
                <>
                  <span className={style.selected_tags}>{items.name} </span>
                </>
              ))}</div>

              {/* new free tag selected one  */}
              {newFreeTag?.map((items, i) => (
                <>
                  <span className={style.selected_tags}>{items} </span>
                </>
              ))}

              {/* input field  */}
              <input
                type="search"
                id="searchInput"
                placeholder=""
                value={multifreeTaginput}
                onChange={(e) => TagFreeMultiple(e.target.value)}
              />

              {/* list of items of pre-defined multifree tag  */}
              {multifreeTagStatus ? (
                <div className={style.list_input_primary}>
                  {multifreeTag?.map((items: any, i) => (
                    <>
                      <div className={style.pointer} onClick={() => selectFreeTag(items)}>
                        <span>{items?.name} </span>
                      </div>
                    </>
                  ))}
                </div>
              ) : (
                ''
              )}

              {/* list of item of user-defined multifreetag  */}
              {newfreeTagStatus ? (
                <div className={style.list_input_primary}>
                  {newfreeTags?.map((items, i) => (
                    <>
                      <div className={style.pointer} onClick={() => selectnewFreeTag(items)}>
                        <span>{items} </span>
                      </div>
                    </>
                  ))}
                </div>
              ) : (
                ''
              )}
            </div>

            {/* cancel button  */}
            <div className={style.cancel_button}>
              <button className={style.pointer} onClick={() => CancelButton()}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.75781 7.75781L16.2431 16.2431"
                    stroke="#272727"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.75691 16.2431L16.2422 7.75781"
                    stroke="#272727"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeTag;
