import { combineReducers } from 'redux';
import {
  BasicInformationReducer,
  GetAllData,
  CreateQuestionReducer,
  QuestionScreenReducer,
  LoggedInUserReducer,
} from './reducer';
export default combineReducers({
  CreateQuestionReducer: CreateQuestionReducer,
  QuestionScreenReducer: QuestionScreenReducer,
  BasicInformationReducer: BasicInformationReducer,
  GetAllData: GetAllData,
  LoggedInUserReducer,
});
