import React, { useEffect, useRef } from 'react';

function Toast({ children, remove, type }: { children: any; remove: any; type: string }) {
  const removeRef = useRef<() => void>();
  removeRef.current = remove;

  useEffect(() => {
    const duration = 5000;
    const id = setTimeout(() => {
      if (removeRef.current) {
        removeRef.current();
      }
    }, duration);

    return () => clearTimeout(id);
  }, []);

  const toastClass = type === 'error' ? 'toast--error' : 'toast--success';

  return (
    <div className={`toast ${toastClass}`}>
      <div className="toast__text">{children}</div>
      <div>
        <button
          onClick={remove}
          className={`toast__close-btn ${type === 'error' ? 'close-btn--error' : 'close-btn--success'}`}
        ></button>
      </div>
    </div>
  );
}

export default Toast;