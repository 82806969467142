import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import style from '../../../assets/styles/previewModalStyle.module.css';
import { GET_QUESTION_PREVIEW } from '../../../action/apiPath';
import { CallWithAuth } from '../../../action/apiActions';
import { useToast } from '../../molecule/common/Toast';

interface PreviewModalProps {
  questionAccess: any;
  selectedQuestion: any;
  isOpen: boolean;
  onClose: () => void;
  iframeSrc: string;
  onEdit: (items: any) => void;
  setIsDuplicateModalOpen:any;
  setModalData:any
  modalData:any
  setIsDuplicatePreview:React.Dispatch<React.SetStateAction<boolean>>;
}

const PreviewModal: React.FC<PreviewModalProps> = ({
  questionAccess,
  selectedQuestion,
  isOpen,
  onClose,
  iframeSrc,
  onEdit,
  setIsDuplicateModalOpen,
  setModalData,
  modalData,
  setIsDuplicatePreview
}) => {
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const dropdownRef = useRef<HTMLDivElement>(null);
  interface PreviewData {
    question_id: string;
    author_by: string;
    created_on: any;
    last_activity: any;
    status: string;
    subject_tags: string;
  }
  const toast = useToast();
  const [previewData, setPreviewData] = useState<PreviewData | null>(null);
  // Handle clicks outside the modal
  const handleOverlayClick = (event: any) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      // onClose();
    }
  };
  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleOverlayClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOverlayClick);
    };
  }, [handleOverlayClick]);

  useEffect(() => {
    if(selectedQuestion){
      const dataToPass = {
        questionId: selectedQuestion.question_id,
        questionBankId: selectedQuestion.question_bank,
      }; 
      setModalData(dataToPass)
    }
  }, []);
  useEffect(() => {

  }, [modalData]);
  //fetch the Preview data from get api

  const fetchQuestionPreview = async () => {
    if (selectedQuestion.question_id) {
      try {
        const url = `${GET_QUESTION_PREVIEW}${selectedQuestion.question_id}/question-preview/`;
        const response = await CallWithAuth('GET', url);
        
        if (response.status && response.res?.status === 200) {
          setPreviewData(response.res.data.data);
          setSelectedStatus(response.res.data.data.status);
        }
      } catch (error) {
        if (toast) {
          toast.add(String(error), 'error');
        }
      }
    }
  };
  useEffect(() => {
    fetchQuestionPreview();
  }, [selectedQuestion, isOpen]);

  // Handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // Check if the dropdown ref exists and doesn't contain the clicked element
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsStatusOpen(false);
      }
    };
    // Add event listener when dropdown is open
    if (isStatusOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    // Clean up the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isStatusOpen]);
  //handle status button click
  const handleButtonClick = () => {
    setIsStatusOpen(true);
  };

  const handleStatusChange = (event: { target: { value: any } }) => {
    const newStatus = event.target.value;
    setSelectedStatus(newStatus);
    setIsStatusOpen(false); // Close dropdown after selection
  };
  const Edit = (items: any) => {
    onEdit(items);
  };
  const duplicateModalOpen = () => {
    setIsDuplicatePreview(true)
    if(selectedQuestion){
      const dataToPass = {
        questionId: selectedQuestion.question_id,
        questionBankId: selectedQuestion.question_bank,
      }; 
      setModalData(dataToPass)
    }
    setIsDuplicateModalOpen(true)
  }
  return (
    <>
      {isOpen ? (
        <div className={style.previewModal}>
          <div className={style.preview_center_div} ref={modalRef}>
            {/* Header Section */}
            <div className={style.preview_header}>
              <div className={style.header_content}>
                <div className={style.question_info}>
                  <div className={style.info_row}>
                    <div>
                      <label htmlFor="Question_id" className={style.label}>
                        Question ID:
                      </label>
                      <span className={style.value}>{previewData?.question_id}</span>
                      <span className={style.separator}></span>
                    </div>
                    <div>
                      <label htmlFor="Authored_by" className={style.label}>
                        Authored By:
                      </label>
                      <span className={style.value}>{previewData?.author_by}</span>
                      <span className={style.separator}></span>
                    </div>
                    <div>
                      <label htmlFor="Created_on" className={style.label}>
                        Created On:
                      </label>
                      <span className={style.value}>{previewData?.created_on}</span>
                    </div>

                    <div className={style.statusDropdown}>
                      <button
                        data-testid="status-dropdown-button"
                        className={style.dropdownButton}
                        onClick={handleButtonClick}
                      >
                        {selectedStatus} <span className={`${style.arrow} ${isStatusOpen ? style.rotated : ''}`}></span>
                      </button>
                      {isStatusOpen && (
                        <div className={style.dropdownContent} ref={dropdownRef}>
                          <label>
                            {' '}
                            <input
                              type="radio"
                              name="status"
                              value="Approved"
                              checked={selectedStatus === 'Approved'}
                              onChange={handleStatusChange}
                            />{' '}
                            Approved
                          </label>
                          <label>
                            {' '}
                            <input
                              type="radio"
                              name="status"
                              value="Rejected"
                              checked={selectedStatus === 'Rejected'}
                              onChange={handleStatusChange}
                            />{' '}
                            Rejected
                          </label>
                          <label>
                            {' '}
                            <input
                              type="radio"
                              name="status"
                              value="In Review"
                              checked={selectedStatus === 'In Review'}
                              onChange={handleStatusChange}
                            />{' '}
                            In Review
                          </label>
                        </div>
                      )}
                    </div>
                  </div>

                  <button className={style.close_button} onClick={onClose}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.34375 6.34375L17.6575 17.6575"
                        stroke="#212529"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.34254 17.6575L17.6562 6.34375"
                        stroke="#212529"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>

                <div className={style.tags_info}>
                  <div className={style.subjectTags}>
                    <span>{previewData?.subject_tags}</span>
                  </div>

                  <div className={style.header_actions}>
                    <div className={style.last_activity}>
                      <label htmlFor="Last_activity" className={style.Lastactivity_label}>
                        Last Activity:
                      </label>
                      <span className={style.Lastactivity_value}>{previewData?.last_activity}</span>
                    </div>

                    {questionAccess.edit || questionAccess.review ? (
                      <div className={style.edit_actions}>
                        <button className={style.edit_button} onClick={() => Edit(selectedQuestion)}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5 18.332H17.5"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.05 3.00002L4.20829 10.2417C3.94996 10.5167 3.69996 11.0584 3.64996 11.4334L3.34162 14.1334C3.23329 15.1084 3.93329 15.775 4.89996 15.6084L7.58329 15.15C7.95829 15.0834 8.48329 14.8084 8.74162 14.525L15.5833 7.28335C16.7666 6.03335 17.3 4.60835 15.4583 2.86668C13.625 1.14168 12.2333 1.75002 11.05 3.00002Z"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.9082 4.20703C10.2665 6.50703 12.1332 8.26536 14.4499 8.4987"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <span>Edit Question</span>
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className={style.duplicateBtnWrap} onClick={duplicateModalOpen}>
                      <span className={style.duplicateBtn}></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Content Section with iframe */}
            <div className={style.preview_content}>
              <iframe src={iframeSrc} className={style.preview_iframe} title="Question Preview" frameBorder="0" />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default PreviewModal;
