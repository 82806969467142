import { React, useEffect, useState } from 'react';

import style from '../../../../../assets/styles/testCreationStyle.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Get_Product_Type, Publish_Test } from '../../../../../redux/action';
import { GET_PDF_DATA } from '../../../../../action/apiPath';
import { CallWithAuth } from '../../../../../action/apiActions';

// const TestLevel = ({ setlevelSetting }) => {

//     const { sectionBody } = useSelector((state) => state.GetAllData);

//     const [settings, setSettings] = useState([
//     ]);

//     useEffect(() => {
//         const numberOfTimesToAdd = sectionBody?.groups?.length

//         const newSettings = Array.from({ length: numberOfTimesToAdd }, () => ({
//             switching_sections: false,
//         }));

//         setSettings(prevSettings => [
//             ...prevSettings,
//             ...newSettings
//         ]);

//     }, [sectionBody])

//     useEffect(() => {
//         setlevelSetting({
//             group_settings: settings,
//         });
//     }, [settings]);

//     // Function to update the switching_sections status of the first element
//     const updateSwitchingSections = (index, value) => {
//         setSettings((prevSettings) => {
//             const updatedSettings = [...prevSettings];
//             updatedSettings[index] = {
//                 ...updatedSettings[index],
//                 switching_sections: Boolean(value),
//             };
//             return updatedSettings;
//         });
//     };

//     return (
//         <div className={style.groupSettingsBox}>
//             {settings?.map((items, i) => (
//                 <div className={style.groupSettings} style={{ marginBottom: i==settings.length-1?"8em":"" }}>
//                     <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                         Group {i + 1} Setting
//                     </h5>
//                     <div className={style.switching}>
//                         <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                             Switching Groups Allowed
//                         </span>
//                         <div className={style.wrapperIcon}>
//                             <div className={` ${style.iconBoxs}  ${items.switching_sections ? "" : style.editOption
//                                 }`} onClick={() => updateSwitchingSections(i, false)}>No</div>

//                             <div className={` ${style.iconBoxs}  ${items.switching_sections ? style.editOption : ""
//                                 }`} onClick={() => updateSwitchingSections(i, true)}>Yes</div>
//                         </div>
//                     </div>
//                 </div>
//             ))}

//             {/* <div className={style.groupSettings}>
//                 <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                     Group 2 Setting
//                 </h5>
//                 <div className={style.switching}>
//                     <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                         Switching Groups Allowed
//                     </span>
//                     <div className={style.wrapperIcon}>
//                         <div className={` ${style.iconBoxs}  ${settings[1].switching_sections ? "" : style.editOption
//                             }`} onClick={() => updateSwitchingSections(1, false)}>No</div>

//                         <div className={` ${style.iconBoxs}  ${settings[1].switching_sections ? style.editOption : ""
//                             }`} onClick={() => updateSwitchingSections(1, true)}>Yes</div>
//                     </div>
//                 </div>
//             </div>

//             <div style={{ marginBottom: "8em" }} className={style.groupSettings}>
//                 <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                     Group 3 Setting
//                 </h5>
//                 <div className={style.switching}>
//                     <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                         Switching Groups Allowed
//                     </span>
//                     <div className={style.wrapperIcon}>
//                         <div className={` ${style.iconBoxs}  ${settings[2].switching_sections ? "" : style.editOption
//                             }`} onClick={() => updateSwitchingSections(2, false)}>No</div>

//                         <div className={` ${style.iconBoxs}  ${settings[2].switching_sections ? style.editOption : ""
//                             }`} onClick={() => updateSwitchingSections(2, true)}>Yes</div>
//                     </div>
//                 </div>
//             </div> */}
//         </div>
//     );
// };

// const GroupLevel = ({ setlevelSetting }) => {

//     const { sectionBody } = useSelector((state) => state.GetAllData);
//     const [settings, setSettings] = useState([
//     ]);

//     useEffect(() => {
//         const numberOfTimesToAdd = sectionBody?.groups?.length

//         const newSettings = Array.from({ length: numberOfTimesToAdd }, () => ({
//             switching_sections: false,
//             group_duration: {
//                 hours: null,
//                 minutes: null,
//                 seconds: null,
//             },
//         }));

//         setSettings(prevSettings => [
//             ...prevSettings,
//             ...newSettings
//         ]);

//     }, [sectionBody])

//     useEffect(() => {
//         setlevelSetting({
//             group_settings: settings,
//         });
//     }, [settings]);

//     // Function to update the switching_sections status of the first element
//     const updateSwitchingSections = (index, value) => {
//         setSettings((prevSettings) => {
//             const updatedSettings = [...prevSettings];
//             updatedSettings[index] = {
//                 ...updatedSettings[index],
//                 switching_sections: Boolean(value),
//             };
//             return updatedSettings;
//         });
//     };

//     const updateGroupDuration = (index, e, type) => {
//         if (type == "hours") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     updatedSettings[index] = { // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             hours: Number(e.target.value), // Update hours
//                         }
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//         if (type == "minutes") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     updatedSettings[index] = { // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             minutes: Number(e.target.value), // Update hours
//                         }
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//         if (type == "seconds") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     updatedSettings[index] = { // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             seconds: Number(e.target.value), // Update hours
//                         }
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//     };

//     return (
//         <div className={style.groupSettingsBox} >

//             {settings?.map((items, i) => (
//                 <div className={style.groupSettings} style={{ marginBottom:  i==settings.length-1?"8em":"" }}>
//                     <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                         Group {i + 1} Setting
//                     </h5>
//                     <div className={style.switching}>
//                         <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                             Switching Groups Allowed
//                         </span>
//                         <div className={style.wrapperIcon}>
//                             <div className={` ${style.iconBoxs}  ${items.switching_sections ? "" : style.editOption
//                                 }`} onClick={() => updateSwitchingSections(i, false)}>No</div>

//                             <div className={` ${style.iconBoxs}  ${items.switching_sections ? style.editOption : ""
//                                 }`} onClick={() => updateSwitchingSections(i, true)}>Yes</div>
//                         </div>
//                     </div>
//                     <div className={style.screenNum}>
//                         <p style={{ fontWeight: 500 }}>Group {i + 1} Duration</p>

//                         <div className={style.timer}>
//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 onChange={(e) => updateGroupDuration(i, e, "hours")}
//                             />
//                             <label htmlFor="minutes">Hours</label>

//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 onChange={(e) => updateGroupDuration(i, e, "minutes")}
//                             />
//                             <label htmlFor="minutes">Minutes</label>

//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 onChange={(e) => updateGroupDuration(i, e, "seconds")}
//                             />
//                             <label htmlFor="second">Seconds</label>
//                         </div>
//                     </div>
//                 </div>
//             ))}

//             {/* <div className={style.groupSettings}>
//                 <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                     Group 2 Setting
//                 </h5>
//                 <div className={style.switching}>
//                     <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                         Switching Groups Allowed
//                     </span>
//                     <div className={style.wrapperIcon}>
//                         <div className={` ${style.iconBoxs}  ${settings[1].switching_sections ? "" : style.editOption
//                             }`} onClick={() => updateSwitchingSections(1, false)}>No</div>

//                         <div className={` ${style.iconBoxs}  ${settings[1].switching_sections ? style.editOption : ""
//                             }`} onClick={() => updateSwitchingSections(1, true)}>Yes</div>
//                     </div>
//                 </div>
//                 <div className={style.screenNum}>
//                     <p style={{ fontWeight: 500 }}>Group 2 Duration</p>

//                     <div className={style.timer}>
//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(1, e, "hours")}
//                         />
//                         <label htmlFor="minutes">Hours</label>

//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(1, e, "minutes")}
//                         />
//                         <label htmlFor="minutes">Minutes</label>

//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(1, e, "seconds")}
//                         />
//                         <label htmlFor="second">Seconds</label>
//                     </div>
//                 </div>
//             </div>

//             <div style={{ marginBottom: "8em" }} className={style.groupSettings}>
//                 <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                     Group 3 Setting
//                 </h5>
//                 <div className={style.switching}>
//                     <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                         Switching Groups Allowed
//                     </span>
//                     <div className={style.wrapperIcon}>
//                         <div className={` ${style.iconBoxs}  ${settings[2].switching_sections ? "" : style.editOption
//                             }`} onClick={() => updateSwitchingSections(2, false)}>No</div>

//                         <div className={` ${style.iconBoxs}  ${settings[2].switching_sections ? style.editOption : ""
//                             }`} onClick={() => updateSwitchingSections(2, true)}>Yes</div>
//                     </div>
//                 </div>
//                 <div className={style.screenNum}>
//                     <p style={{ fontWeight: 500 }}>Group 3 Duration</p>

//                     <div className={style.timer}>
//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(2, e, "hours")}
//                         />
//                         <label htmlFor="minutes">Hours</label>

//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(2, e, "minutes")}
//                         />
//                         <label htmlFor="minutes">Minutes</label>

//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(2, e, "seconds")}
//                         />
//                         <label htmlFor="second">Seconds</label>
//                     </div>
//                 </div>
//             </div> */}
//         </div>
//     );
// };

// const SectionLevel = ({ setlevelSetting }) => {

//     const { sectionBody } = useSelector((state) => state.GetAllData);

//     const [settings, setSettings] = useState([
//     ]);

//     useEffect(() => {

//         for (let i = 0; i < sectionBody?.groups?.length; i++) {
//             const numberOfTimesToAdd = sectionBody?.groups?.length
//             const sectionTime = sectionBody?.groups[i].sections.length

//             var newSettings = Array.from({ length: numberOfTimesToAdd }, () => ({
//                 switching_sections: false,
//                 group_duration: {
//                     hours: null,
//                     minutes: null,
//                     seconds: null,
//                 },
//                 sections_durations: Array.from({ length: sectionTime }, () => ({
//                     hours: null,
//                     minutes: null,
//                     seconds: null,
//                 })),
//             }));
//         }

//         setSettings(prevSettings => [
//             ...prevSettings,
//             ...newSettings
//         ]);

//     }, [sectionBody])

//     useEffect(() => {
//         setlevelSetting({
//             group_settings: settings,
//         });
//     }, [settings]);

//     // Function to update the switching_sections status of the first element
//     const updateSwitchingSections = (index, value) => {
//         setSettings((prevSettings) => {
//             const updatedSettings = [...prevSettings];
//             updatedSettings[index] = {
//                 ...updatedSettings[index],
//                 switching_sections: Boolean(value),
//             };
//             return updatedSettings;
//         });
//     };

//     const updateGroupDuration = (index, e, type) => {
//         if (type == "hours") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     updatedSettings[index] = { // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             hours: Number(e.target.value), // Update hours
//                         }
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//         if (type == "minutes") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     updatedSettings[index] = { // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             minutes: Number(e.target.value), // Update hours
//                         }
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//         if (type == "seconds") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     updatedSettings[index] = { // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             seconds: Number(e.target.value), // Update hours
//                         }
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//     };

//     const updateSectionDuration = (index, e, type, index2) => {
//         if (type == "hours") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     if (updatedSettings[index].sections_durations && updatedSettings[index].sections_durations.length > 0) { // Check if sections_durations array exists and has elements
//                         updatedSettings[index].sections_durations[index2] = { // Update the first index of sections_durations
//                             ...updatedSettings[index].sections_durations[index2], // Maintain other properties
//                             hours: Number(e.target.value) // Update hours
//                         };
//                     }
//                 }
//                 return updatedSettings;
//             });

//         }
//         if (type == "minutes") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     if (updatedSettings[index].sections_durations && updatedSettings[index].sections_durations.length > 0) { // Check if sections_durations array exists and has elements
//                         updatedSettings[index].sections_durations[index2] = { // Update the first index of sections_durations
//                             ...updatedSettings[index].sections_durations[index2], // Maintain other properties
//                             minutes: Number(e.target.value) // Update hours
//                         };
//                     }
//                 }
//                 return updatedSettings;
//             });
//         }
//         if (type == "seconds") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     if (updatedSettings[index].sections_durations && updatedSettings[index].sections_durations.length > 0) { // Check if sections_durations array exists and has elements
//                         updatedSettings[index].sections_durations[index2] = { // Update the first index of sections_durations
//                             ...updatedSettings[index].sections_durations[index2], // Maintain other properties
//                             seconds: Number(e.target.value) // Update hours
//                         };
//                     }
//                 }
//                 return updatedSettings;
//             });
//         }
//     };

//     return (
//         <div className={style.groupSettingsBox}>
//             {settings?.map((items, i) => (
//                 <div className={style.groupSettings} style={{ marginBottom:  i==settings.length-1?"8em":"" }}>
//                     <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                         Group {i + 1} Setting
//                     </h5>
//                     <div className={style.switching}>
//                         <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                             Switching Groups Allowed
//                         </span>
//                         <div className={style.wrapperIcon}>
//                             <div className={` ${style.iconBoxs}  ${items.switching_sections ? "" : style.editOption
//                                 }`} onClick={() => updateSwitchingSections(i, false)}>No</div>

//                             <div className={` ${style.iconBoxs}  ${items.switching_sections ? style.editOption : ""
//                                 }`} onClick={() => updateSwitchingSections(i, true)}>Yes</div>
//                         </div>
//                     </div>
//                     <div className={style.screenNum}>
//                         <p style={{ fontWeight: 500 }}>Group {i + 1} Duration</p>

//                         <div className={style.timer}>
//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 onChange={(e) => updateGroupDuration(i, e, "hours")}
//                             />
//                             <label htmlFor="minutes">Hours</label>

//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 onChange={(e) => updateGroupDuration(i, e, "minutes")}
//                             />
//                             <label htmlFor="minutes">Minutes</label>

//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 onChange={(e) => updateGroupDuration(i, e, "seconds")}
//                             />
//                             <label htmlFor="second">Seconds</label>
//                         </div>
//                     </div>
//                     <div style={{ margin: "2em 0em 0em 3em" }} className={style.screenNum}>
//                         {items.sections_durations.map((item, key) => (
//                             <>
//                                 <p style={{ fontWeight: 500 }}>Section {key + 1} Duration</p>

//                                 <div className={style.timer}>
//                                     <input
//                                         className={style.timer_input}
//                                         type="number"
//                                         name="number"
//                                         min="-100"
//                                         max="100"
//                                         step="1"
//                                         onChange={(e) => updateSectionDuration(i, e, "hours", key)}
//                                     />
//                                     <label htmlFor="minutes">Hours</label>

//                                     <input
//                                         className={style.timer_input}
//                                         type="number"
//                                         name="number"
//                                         min="-100"
//                                         max="100"
//                                         step="1"
//                                         onChange={(e) => updateSectionDuration(i, e, "minutes", key)}
//                                     />
//                                     <label htmlFor="minutes">Minutes</label>

//                                     <input
//                                         className={style.timer_input}
//                                         type="number"
//                                         name="number"
//                                         min="-100"
//                                         max="100"
//                                         step="1"
//                                         onChange={(e) => updateSectionDuration(i, e, "seconds", key)}
//                                     />
//                                     <label htmlFor="second">Seconds</label>
//                                 </div>
//                             </>
//                         ))}

//                     </div>
//                 </div>
//             ))}
//         </div>
//     );
// };

const AdvanceSettings = ({ publish, setButtonStatus, setpdfResult, pdfresult }) => {
  const dispatch = useDispatch();
  const { testCreationId } = useSelector((state) => state.GetAllData);

  const [settings, setSettings] = useState({
    calculator: null,
    result_date_time: null,
    test_duration_at: 0,
    overall_test_duration: {
      hours: null,
      minutes: null,
      seconds: null,
    },
  });

  useEffect(() => {
    if (publish) {
      dispatch(Publish_Test(settings, testCreationId));
    }
  }, [publish]);

  const handleCalculator = (e) => {
    setSettings((prevState) => ({
      ...prevState,
      calculator: Number(e.target.value),
    }));
  };

  const handleResultDateTime = (e) => {
    setSettings((prevState) => ({
      ...prevState,
      result_date_time: e.target.value,
    }));
  };

  const handleOverAllDuration = (e, type) => {
    if (type == 'hours') {
      setSettings((prevSettings) => ({
        ...prevSettings,
        overall_test_duration: {
          ...prevSettings.overall_test_duration,
          hours: Number(e.target.value),
        },
      }));
    }
    if (type == 'minutes') {
      setSettings((prevSettings) => ({
        ...prevSettings,
        overall_test_duration: {
          ...prevSettings.overall_test_duration,
          minutes: Number(e.target.value),
        },
      }));
    }
    if (type == 'seconds') {
      setSettings((prevSettings) => ({
        ...prevSettings,
        overall_test_duration: {
          ...prevSettings.overall_test_duration,
          seconds: Number(e.target.value),
        },
      }));
    }
  };

  const handleLevel = (e) => {
    setSettings((prevState) => ({
      ...prevState,
      test_duration_at: Number(e.target.value),
    }));
  };

  useEffect(() => {
    if (
      settings.calculator != null &&
      settings.result_date_time != null &&
      settings.overall_test_duration.hours != null &&
      settings.overall_test_duration.minutes != null &&
      settings.overall_test_duration.seconds != null
    ) {
      setButtonStatus(true);
    }
  }, [settings]);

  const PdfCheck = async () => {
    const url = GET_PDF_DATA + testCreationId + '/get_test_paper_pdf/';
    const PdfChecking = await CallWithAuth('GET', url);
    if (PdfChecking.status && PdfChecking.res.status === 200) {
      setpdfResult(PdfChecking.res.data.test_paper_pdf_url);
    }
  };

  useEffect(() => {
    if (pdfresult == null) {
      const intervalId = setInterval(() => {
        // Call your function here
        PdfCheck();
      }, 2000); // 2000 milliseconds = 2 seconds

      // Clear the interval when the component unmounts or when the dependency array changes
      return () => clearInterval(intervalId);
    }
  }, [pdfresult]);

  return (
    <div className={style.masterTestCreation}>
      <form className={` ${style.testCreationArea} ${style.AdvanceBox}`}>
        <div className={style.AdvanceSettingsContainer}>
          <h5 style={{ fontSize: '18px' }} className={style.testMarklabel}>
            Advance Settings
          </h5>

          <div className={style.bound}>
            {/* Test type */}
            <div className={style.templateDrop}>
              <div className={style.templateDrop_item}>
                <div className={style.templateGroup}>
                  <span style={{ fontSize: '14px', fontWeight: '500' }}>Calculator To Use</span>
                  <select
                    name="question"
                    id="question"
                    className={style.template_drop_filter}
                    onClick={handleCalculator}
                  >
                    <option value={0}>---- None ----</option>
                    <option value={1}>Basic</option>
                    <option value={2}>Scientific</option>
                  </select>
                </div>
              </div>
            </div>

            <div className={style.templateDrop}>
              <div className={style.templateDrop_item}>
                <div className={style.templateGroup}>
                  <span style={{ fontSize: '14px', fontWeight: '500' }}>Result Date/ Time</span>
                  <input
                    type="datetime-local"
                    name="question"
                    id="question"
                    className={style.template_drop_filter}
                    onChange={handleResultDateTime}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className={style.switching}>
                        <span style={{ fontSize: "14px", fontWeight: "500" }}>
                            Switching Groups Allowed
                        </span>
                        <div className={style.wrapperIcon}>
                            <div
                                className={` ${style.iconBoxs} ${settings.switching_groups_allowed ? "" : style.editOption
                                    }`}
                                onClick={() => handleSwitchGroup(false)}
                            >
                                No
                            </div>

                            <div
                                className={` ${style.iconBoxs} ${settings.switching_groups_allowed ? style.editOption : ""
                                    }`}
                                onClick={() => handleSwitchGroup(true)}
                            >
                                Yes
                            </div>
                        </div>
                    </div> */}

          <p style={{ fontWeight: '500', marginTop: '10px' }} className={style.marketLogic}>
            Test Duration At
          </p>
          <div style={{ fontSize: '13px' }} className={style.levels}>
            <input type="radio" name="markingLogic" defaultChecked value={0} onClick={handleLevel} />
            <label htmlFor="test level">Test Level</label>
            <input style={{ marginLeft: '20px' }} name="markingLogic" type="radio" value={1} onClick={handleLevel} />
            <label htmlFor="section level">Group Level</label>
            <input style={{ marginLeft: '20px' }} name="markingLogic" type="radio" value={2} onClick={handleLevel} />
            <label htmlFor="section level">Section Level Level</label>
          </div>

          <div className={style.screenNum}>
            <p style={{ fontWeight: 500 }}>Overall Test Duration</p>

            <div className={style.timer}>
              <input
                className={` ${style.timer_input} ${style.fullbg_section}`}
                type="number"
                name="number"
                min="-100"
                max="100"
                step="1"
                onChange={(e) => handleOverAllDuration(e, 'hours')}
              />
              <label htmlFor="minutes">Hours</label>

              <input
                className={` ${style.timer_input} ${style.fullbg_section}`}
                type="number"
                name="number"
                min="-100"
                max="100"
                step="1"
                onChange={(e) => handleOverAllDuration(e, 'minutes')}
              />
              <label htmlFor="minutes">Minutes</label>

              <input
                className={` ${style.timer_input} ${style.fullbg_section}`}
                type="number"
                name="number"
                min="-100"
                max="100"
                step="1"
                onChange={(e) => handleOverAllDuration(e, 'seconds')}
              />
              <label htmlFor="second">Seconds</label>
            </div>
          </div>

          {/* {settings.test_duration_at == 0 ? <TestLevel setlevelSetting={setlevelSetting} /> : ""}
                    {settings.test_duration_at == 1 ? <GroupLevel setlevelSetting={setlevelSetting} /> : ""}
                    {settings.test_duration_at == 2 ? <SectionLevel setlevelSetting={setlevelSetting} /> : ""} */}
        </div>
      </form>
    </div>
  );
};

export default AdvanceSettings;
