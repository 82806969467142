import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import style from '../../../assets/styles/modalStyle.module.css';

interface ModalData {
  title: string;
  description: string;
  cancelButton: string;
  saveButton: string;
}

interface MediumModalProps {
  forwardButton: () => void;
  modalData: ModalData;
  modalMedium: boolean;
  closeModalMedium: () => void;
}

const MediumModal: React.FC<MediumModalProps> = ({ forwardButton, modalData, modalMedium, closeModalMedium }) => {
  // ___________ forward button calling on save and make action accordingly ___________
  const ForwardButton = () => {
    forwardButton();
    modalData.title !== text && closeModalMedium();
  };
  const text = 'Changing the passage will modify the answers.';
  const text2 = 'Are You Sure, Do You Want To Cancel Question Creation?';
  const text3 = 'Requires a Review';
  // outside clickable close modal
  const modalRef = useRef<HTMLDivElement>(null);

  const handleOverlayClick = (event: any) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModalMedium();
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleOverlayClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOverlayClick);
    };
  }, [handleOverlayClick]);

  return (
    <>
      {modalMedium ? (
        <div
          className={
            modalData.title === text || modalData.title === text2 || modalData.title === text3
              ? `${style.mediumModal} highlightModel`
              : `${style.mediumModal}`
          }
        >
          <div className={style.center_div} ref={modalRef}>
            <h3>{modalData.title}</h3>
            <p>{modalData.description}</p>

            {/* SAVE BUTTON AND CANCEL BUTTON  */}
            <div className={style.saveButton_questionCreation_Modal}>
              <div id="" className={` ${style.cancel} ${style.pointer}`} onClick={() => closeModalMedium()}>
                {modalData.cancelButton}
              </div>
              <div>
                <button id="modelClick" className={style.blue_button} onClick={() => ForwardButton()}>
                  {' '}
                  {modalData.saveButton}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default MediumModal;
