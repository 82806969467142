// import { useContext } from 'react';
// import ToastContext from './context';

// function useToast() {
//   const context = useContext<any>(ToastContext);
// if(context ){

//   return { add: context.add, remove: context.remove };
// }
// }

// export default useToast;
import { useContext } from 'react';
import ToastContext from './context';

function useToast() {
  const context = useContext<any>(ToastContext);
  if (context) {
    return {
      add: (content: string, type: string) => context.add(content, type),
      remove: context.remove,
    };
  }
}

export default useToast;
