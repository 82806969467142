import React, { useEffect, useRef, useState } from 'react';
import style from '../../../../../../assets/styles/createQuestion.module.css';
import QuillEditor from '../../../../../atoms/QuillEditor';
import arrowIcon from '../../../../../../assets/images/upArrow.svg';

interface Option {
  id: number;
  value: string;
  isSelected: boolean;
  nonDeletable?: boolean;
}

interface Options {
  [key: string]: Option[];
}

interface FractionInputProps {
  body: any[]; // Replace 'any' with specific type for `body` if known
  questionbutton: number;
  setbodyformultiquestion: (body: any[]) => void;
}

const FractionInput: React.FC<FractionInputProps> = ({ body, questionbutton, setbodyformultiquestion }) => {
  const [questionIdentifier, setQuestionIdentifier] = useState<string | undefined>(
    body[questionbutton]?.question_short_identifier
  );
  const [questionDirection, setQuestionDirection] = useState<string | undefined>(
    body[questionbutton]?.question_direction
  );
  const [compose_text, setCompose_text] = useState<string | undefined>(body[questionbutton]?.compose_question);
  const [explanation_text, setExplanation_text] = useState<string | undefined>(body[questionbutton]?.explaination_text);
  const [videoId, setvideoId] = useState<string | undefined>(body[questionbutton]?.video_id);
  const editorRef = useRef<any>(null);
  // Use the existing selected value or default to empty string
  const [answer_format, setAnswer_format] = useState<any>(body[questionbutton]?.answer_format ?? 'Select');

  const [numerator, setNumerator] = useState<any>(body[questionbutton]?.options?.[0]?.numerator ?? '');
  const [denominator, setDenominator] = useState<any>(
    body[questionbutton]?.options?.[0]?.denominator ?? ''
  );
  //handle the select answer format
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if(event.target.value === 'Select') {
      setAnswer_format(event.target.value);
    } else {
      const answerFormat = Number(event.target.value)
      setAnswer_format(answerFormat)
    }
  };

 // Modified to handle type conversion
 const handleNumeratorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const input = event.target.value;
  // Check if input is a valid number
  if (/^-?\d+$/.test(input)) {
    setNumerator(Number(input));
  } else {
    setNumerator(input);
  }
};

// Modified to handle type conversion
const handleDenominatorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const input = event.target.value;
  // Check if input is a valid number
  if (/^-?\d+$/.test(input)) {
    setDenominator(Number(input));
  } else {
    setDenominator(input);
  }
};
  
  useEffect(() => {
    if(body[questionbutton]?.answer_format>=0){
      setAnswer_format(body[questionbutton]?.answer_format)
    }
  },[body])

  const AddUpdateBody = () => {
    const updatebody = [...body];
    updatebody[questionbutton] = {
      idx: questionbutton,
      question_type_id: body[questionbutton]?.question_type_id,
      question_short_identifier: questionIdentifier,
      question_direction: questionDirection,
      compose_question: compose_text,
      answer_format: answer_format,
      options: [{
        numerator: numerator,
        denominator: denominator
      }],
      video_id: videoId || null,
      explaination_text: explanation_text || null,
    };
    setbodyformultiquestion(updatebody);
  };

  useEffect(() => {
    AddUpdateBody();
  }, [
    questionIdentifier,
    questionDirection,
    compose_text,
    numerator,
    denominator,
    answer_format,
    videoId,
    explanation_text,
  ]);

  function b64DecodeUnicode(str: string) {
    try {
      return decodeURIComponent(
        atob(str)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
    } catch (e) {
      return '';
    }
  }

  const setRef = (data: any) => {
    editorRef.current = data;
  };

  return (
    <div className={style.tinymce_text} style={{ marginTop: '15px' }}>
      {/* add Question Short Identifier with tnymce*/}
      <div className={style.Question_Direction}>
        <p>
          Question Short Identifier <b className={style.red_mendantory}>*</b>
        </p>
        <textarea
          placeholder="Enter Question Short Identifier"
          className={`${style.question_identifier} ${style.Shortquestion}`}
          value={questionIdentifier}
          onChange={(e) => setQuestionIdentifier(e.target.value)}
        />
      </div>

      {/* add Question Direction with tnymce*/}
      <div className={`${style.Question_Direction} ${style.QuestionDirection}`}>
        <p>
          Enter Question Direction 
        </p>
        <div style={{ height: 'auto', flexGrow: '1' }}>
          <QuillEditor
            defaultValue={questionDirection != null ? questionDirection : ''}
            onChange={(text) => setQuestionDirection(text)}
          />
          </div>
      </div>

      {/* add compose quetion*/}
      <div className={`${style.explanation_text} ${style.explanationtext}`}>
        <div className={style.questionWrapper}>
          <p className="questionContainer">
            {/* Compose question* <b className={style.red_mendantory}>*</b> */}
            Compose question <b className={style.red_mendantory}>*</b>
          </p>
        </div>

        <div className={style.ComposeQuestion}>
          <QuillEditor
            refSetter={(data: any) => setRef(data)}
            ref={editorRef}
            defaultValue={compose_text != null ? b64DecodeUnicode(compose_text) : ''}
            onChange={(text) => setCompose_text(btoa(text))}
          />
        </div>
      </div>

      {/* choose answer type*/}
      <div className={style.Fractionanswertype}>
        <p>
          Choose Answer Format <b className={style.red_mendantory}>*</b>
        </p>
        <div style={{ position: 'relative' }}>
          <select
            id="answer-format"
            data-testid="answer-format-select"
            value={answer_format}
            onChange={handleChange}
            className={style.answerFormatSelect}
          >
            <option value="Select" disabled hidden>
              Select
            </option>
            <option value={0}>Numerical</option>
            <option value={1}>Alphanumeric</option>
            <option value={2}>Alphabetical</option>
            <option value={3}>User Keyboard</option>
          </select>
          {/* Custom Arrow Icon */}
          <img src={arrowIcon} alt="Custom Arrow" className={style.dropdownArrow} />
        </div>
      </div>
      {/* enter correct answer */}
      <div className={style.correctAnswer}>
        <p>
          Enter Correct Answer <b className={style.red_mendantory}>*</b> <br />
          <span className={style.sentenceNote}>
            Note: Please ensure that all fraction responses are provided in their lowest forms.
          </span>
        </p>
        <div className={style.fractionInput}>
          <input
            className={style.NumeratorInput}
            type="text"
            placeholder="Numerator"
            value={numerator}
            onChange={handleNumeratorChange}
          />
          <span className={style.Dividor}></span>
          <input
            className={style.DenominatorInput}
            type="text"
            placeholder="Denominator"
            value={denominator}
            onChange={handleDenominatorChange}
          />
        </div>
      </div>

      <span className={style.seprator}></span>
      {/* add Video Id */}
      <div className={`${style.explanation_text} ${style.videoId}`}>
        <p>
          Video Id <b className={style.red_mendantory}>*</b>
        </p>
        <div className={style.explanation_video}>
          <input
            type="text"
            value={videoId}
            placeholder="Enter Video ID"
            onChange={(e) => setvideoId(e.target.value)}
          />
        </div>
      </div>

      {/* add explanation_text with tnymce*/}
      <div className={style.explanation_text}>
        <p>
          Add Explanation Text <b className={style.red_mendantory}>*</b>
        </p>
        <div style={{ height: 'auto', flexGrow: '1' }}>
          <QuillEditor
            defaultValue={explanation_text != null ? b64DecodeUnicode(explanation_text) : ''}
            onChange={(text) => setExplanation_text(btoa(text))}
          />
        </div>
      </div>
    </div>
  );
};

export default FractionInput;
