import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import style from '../../../assets/styles/modalStyle.module.css';
import warningIcon from '../../../assets/images/warningIcon.png';
import closeIcon from '../../../assets/images/closeIcon.png';
interface ModalData {
  title: string;
  description: string;
  cancelButton: string;
  saveButton: string;
  substitle?: string;
  className?: string;
}

interface MediumModalProps {
  forwardButton: () => void;
  modalData: ModalData;
  modalMedium: boolean;
  closeModalMedium: () => void;
}

const LockModal: React.FC<MediumModalProps> = ({ forwardButton, modalData, modalMedium, closeModalMedium }) => {
  // ___________ forward button calling on save and make action accordingly ___________
  const ForwardButton = () => {
    forwardButton();
    closeModalMedium();
  };

  // outside clickable close modal
  const modalRef = useRef<HTMLDivElement>(null);

  const handleOverlayClick = (event: any) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModalMedium();
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleOverlayClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOverlayClick);
    };
  }, [handleOverlayClick]);

  return (
    <>
      {modalMedium ? (
        <div className={`${style.mediumModal} lockModelBreaks`}>
          <div className={`${style.center_div} ${modalData?.className}`} ref={modalRef}>
            <div className="headerModel">
              <div className="headerText">
                {modalData.substitle && <img className="headerIcon" src={warningIcon} alt="" />}
                <p style={{ marginLeft: modalData.substitle ? 0 : -137 }} className="heaserTitle">
                  {modalData.title}
                </p>
              </div>
              <div style={{ cursor: 'pointer' }} onClick={() => closeModalMedium()}>
                <img src={closeIcon} />
              </div>
            </div>

            {modalData.substitle && <p className="headerSubtitle">{modalData.substitle}</p>}
            <p style={{ marginTop: modalData.substitle ? 0 : 55 }} className="headerContent">
              {modalData.description}
            </p>

            {/* SAVE BUTTON AND CANCEL BUTTON  */}
            <div className={`${style.saveButton_questionCreation_Modal} modalSaveContainer`}>
              {modalData.cancelButton && (
                <div className={` ${style.cancel} ${style.pointer}`} onClick={() => closeModalMedium()}>
                  {modalData.cancelButton}
                </div>
              )}
              <div>
                <button className={style.blue_button} onClick={() => ForwardButton()}>
                  {' '}
                  {modalData.saveButton}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default LockModal;
