import React, { useEffect, useRef, useState } from 'react';
import style from '../../../../../../assets/styles/createQuestion.module.css';
import QuillEditor from '../../../../../atoms/QuillEditor';
import MediumModal from '../../../../../atoms/Modal/MediumModal';

interface Option {
  id: number;
  value: string;
  isCorrect: boolean;
}

interface HighlightCorrectSentenceProps {
  body: any[]; // Replace 'any' with specific type for `body` if known
  questionbutton: number;
  data: string;
  setbodyformultiquestion: (body: any[]) => void;
  setPassage_text: () => void;
  passageText: any;
  setShowModal: (item: boolean) => void;
  showModal: boolean;
  editor: any;
}

const HighlightCorrectSentence: React.FC<HighlightCorrectSentenceProps> = ({
  data,
  body,
  questionbutton,
  setbodyformultiquestion,
  setPassage_text,
  passageText,
  setShowModal,
  showModal,
  editor,
}) => {
  const [questionIdentifier, setQuestionIdentifier] = useState<string | undefined>(
    body[questionbutton]?.question_short_identifier
  );
  const [questionDirection, setQuestionDirection] = useState<string | undefined>(
    body[questionbutton]?.question_direction
  );
  const [compose_text, setCompose_text] = useState<string | undefined>(body[questionbutton]?.compose_question);
  const [explanation_text, setExplanation_text] = useState<string | undefined>(body[questionbutton]?.explaination_text);
  const [videoId, setvideoId] = useState<string | undefined>(body[questionbutton]?.video_id);
  const [options, setOptions] = useState<Option[]>(body[questionbutton]?.options ? body[questionbutton]?.options : []);
  const [passageData, setPassageData] = useState(data ? data : '');
  const [modalMedium, setModalMedium] = useState(false);
  const [modalData, setModalModalData] = useState({
    title: 'Changing the passage will modify the answers.',
    description: 'Correct option and alternate options values will be reset. Please confirm your action',
    cancelButton: 'Cancel',
    saveButton: 'Yes, Change',
  });
  const onPassageTextChnage = passageText();
  useEffect(() => {
    if (data) {
      setModalMedium(false);
      handleConfirmDelete(data);
      setPassageData(data);
      setShowModal(true);
    }
  }, [data]);

  useEffect(() => {
    if (
      passageData !== onPassageTextChnage &&
      onPassageTextChnage &&
      !modalMedium &&
      showModal &&
      options.length > 0
      // Check if the length increased
    ) {
      openModalMedium();
    }
  }, [onPassageTextChnage, options.length, modalMedium]);
  function splitParagraph(paragraph: string) {
    if (paragraph) {
      if (typeof paragraph !== 'string') {
        throw new Error('Input must be a string');
      }
      // Match sentences ending with ., !, or ? (including the mark)
      const regex = /[^.!?]*[.!?]/g;
      const lines = paragraph.match(regex) || [];

      // Trim spaces and return the lines
      const sentences = lines.map((line) => line.trim());
      const optionsLabels = convertToOptionsArray(sentences);
      setOptions([...optionsLabels]);
    }
  }

  const convertToOptionsArray = (sentences: any[]) => {
    return sentences.map((sentence, i) => ({
      id: i,
      value: sentence,
      isCorrect: false,
    }));
  };

  const handleOptionSelect = (id: number) => {
    setOptions((prevOptions) =>
      prevOptions.map((option, index) => ({
        ...option,
        isCorrect: option.id === id ? true : false,
      }))
    );
  };

  const AddUpdateBody = () => {
    const updatebody = [...body];
    updatebody[questionbutton] = {
      idx: questionbutton,
      question_type_id: body[questionbutton]?.question_type_id,
      question_short_identifier: questionIdentifier,
      question_direction: questionDirection,
      compose_question: compose_text,
      options: options,
      video_id: videoId || null,
      explaination_text: explanation_text || null,
    };
    setbodyformultiquestion(updatebody);
  };

  useEffect(() => {
    // if (data) {
    //   splitParagraph(data);
    // }
    // if (body[questionbutton]?.options) {
    //   const transformOptions = (body[questionbutton]?.options);
    //   setOptions(transformOptions);
    // } else {
    //   setOptions([]);
    // }
  }, []);

  useEffect(() => {
    AddUpdateBody();
  }, [questionIdentifier, questionDirection, compose_text, options, videoId, explanation_text]);

  function b64DecodeUnicode(str: string) {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  const handleEditorClear = () => {
    setCompose_text('');
  };

  const handleConfirmDelete = (data: string) => {
    // Add your delete logic here
    splitParagraph(data);
  };

  const openModalMedium = () => {
    setModalMedium(true);
  };

  const closeModalMedium = () => {
    setModalMedium(false);
  };

  return (
    <div className={style.tinymce_text} style={{ marginTop: '15px' }}>
      {/* add Question Short Identifier with tnymce*/}
      <div className={style.Question_Direction}>
        <p>
          Question Short Identifier <b className={style.red_mendantory}>*</b>
        </p>
        <textarea
          placeholder="Enter Question Short Identifier"
          className={`${style.question_identifier} ${style.Shortquestion}`}
          value={questionIdentifier}
          onChange={(e) => setQuestionIdentifier(e.target.value)}
        />
      </div>

      {/* add Question Direction with tnymce*/}
      <div className={`${style.Question_Direction} ${style.QuestionDirection}`}>
        <p>Enter Question Direction</p>
        <div style={{ height: 'auto', flexGrow: '1' }}>
          <QuillEditor
            defaultValue={questionDirection != null ? questionDirection : ''}
            onChange={(text) => setQuestionDirection(text)}
          />
        </div>
      </div>

      {/* add compose quetion*/}
      <div className={`${style.explanation_text} ${style.explanationtext}`}>
        <div className={style.questionWrapper}>
          <p className="questionContainer">
            Compose question <b className={style.red_mendantory}>*</b>
          </p>
          <button
            className={style.deleteButtonForQuestion}
            aria-label="Remove this question"
            onClick={handleEditorClear}
          >
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.8504 9.89062L18.2004 19.9606C18.0904 21.5306 18.0004 22.7506 15.2104 22.7506H8.79039C6.00039 22.7506 5.91039 21.5306 5.80039 19.9606L5.15039 9.89062"
                stroke="#272727"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21 6.72656C17.67 6.39656 14.32 6.22656 10.98 6.22656C9 6.22656 7.02 6.32656 5.04 6.52656L3 6.72656"
                stroke="#272727"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.5 5.72L8.72 4.41C8.88 3.46 9 2.75 10.69 2.75H13.31C15 2.75 15.13 3.5 15.28 4.42L15.5 5.72"
                stroke="#272727"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p className="remove-text">Remove this Question</p>
          </button>
        </div>

        <div className={style.ComposeQuestion}>
          <QuillEditor
            toolbarList={false}
            defaultValue={compose_text != null ? b64DecodeUnicode(compose_text) : ''}
            onChange={(text) => setCompose_text(btoa(text))}
          />
        </div>
      </div>

      {/* <span className={style.seprator}></span> */}

      {/*  Select the correct sentence */}
      <div className={`${style.explanation_text} ${style.videoId}`}>
        {options.length > 0 && (
          <p>
            Select the correct sentence <b className={style.red_mendantory}>*</b>
            <br />
            <span className={style.sentenceNote}>
              Note: If you have changed the passage above, make sure you click outside the passage text editor to
              repopulate the list of sentences below.
            </span>
          </p>
        )}

        <div style={{ width: '100%' }} className={style.optionsList}>
          {options.length > 0 &&
            options?.map((option, index) => (
              <div key={option.id} className={style.optionItem}>
                <div className={style.sentecnceInputWrapper}>
                  <input
                    type="radio"
                    name={`correctSentences`}
                    checked={option.isCorrect}
                    onChange={() => handleOptionSelect(option.id)}
                    className={style.radioInput}
                  />
                  <div style={{ width: '94%' }} className={style.sentecnceInput}>
                    {option.value}
                  </div>
                  {/* <input
                    type="text"
                    disabled={true}
                    value={option.value}
                    //placeholder="Enter Option"
                    className={style.sentecnceInput}
                  /> */}
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* add Video Id */}
      <div className={`${style.explanation_text} ${style.videoId}`}>
        <p>
          Video Id <b className={style.red_mendantory}>*</b>
        </p>
        <div className={style.explanation_video}>
          <input
            type="text"
            value={videoId}
            placeholder="Enter Video ID"
            onChange={(e) => setvideoId(e.target.value)}
          />
        </div>
      </div>

      {/* add explanation_text with tnymce*/}
      <div className={style.explanation_text}>
        <p>
          Add Explanation Text <b className={style.red_mendantory}>*</b>
        </p>
        <div style={{ height: 'auto', flexGrow: '1' }}>
          <QuillEditor
            defaultValue={explanation_text != null ? b64DecodeUnicode(explanation_text) : ''}
            onChange={(text) => setExplanation_text(btoa(text))}
          />
        </div>
      </div>

      <MediumModal
        modalMedium={modalMedium}
        forwardButton={() => {
          setModalMedium(false);
          setShowModal(false);

          editor?.focus();
          // const content = editor?.getBody(); // Get the editor's content body
          // const lastNode = content?.lastChild; // Get the last child node

          // if (lastNode) {
          //   editor?.selection?.select(lastNode, true);
          //   editor?.selection?.collapse(false); // Collapse the selection to the end
          // }
        }}
        closeModalMedium={() => {
          closeModalMedium();
          editor?.setContent(`<p>${passageData}</p>`);
          setShowModal(true);
        }}
        modalData={modalData}
      />
    </div>
  );
};

export default HighlightCorrectSentence;
