import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import profile from '../../../assets/images/profile.png';
import Header from './RightPanelHeader/Header';
import { CallWithAuth } from '../../../action/apiActions';
import { GET_QUESTIONBANK } from '../../../action/apiPath';
import style from '../../../assets/styles/rightPanelStyle.module.css';

// Interface for QuestionBank data
interface QuestionBankData {
  id: number;
  name: string;
  [key: string]: any; 
}
// Interface for Master/Report data items
interface DataItem {
  id: number;
  name: string;
}
// Props interface
interface RightPanelTopMenuProps {
  setQuestionBankId: ((id: number) => void) | any;
  title: string;
}
// API response interface
interface apiResponse {
  status: boolean;
  res: {
    data: QuestionBankData[];
  };
}
const RightPanelTopMenu: React.FC<RightPanelTopMenuProps> = ({ setQuestionBankId, title }) => {
  // State with type definition
  const [questionBank, setQuestionBank] = useState<QuestionBankData[] | null>(null);
  // Get the first_name and last_name from the userInfo object in local storage
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const firstName = userInfo.first_name || '';
  const lastName = userInfo.last_name || '';
  const getQuestionBank = async (): Promise<void> => {
    const GetData: any | apiResponse = await CallWithAuth('GET', GET_QUESTIONBANK);
    if (GetData?.status && Array.isArray(GetData?.res?.data)) {
      setQuestionBank(GetData.res.data);
      if (setQuestionBankId !== null) {
        setQuestionBankId(GetData.res.data[0].id);
      }
    }
  };

  useEffect(() => {
    getQuestionBank();
  }, []);
  
  const MasterData: DataItem[] = [
    {
      id: 1,
      name: 'Category',
    },
    {
      id: 2,
      name: 'Identifiers',
    },
    {
      id: 3,
      name: 'LOD',
    },
    {
      id: 4,
      name: 'Question Bank',
    },
    {
      id: 5,
      name: 'Subject Pipeline',
    },
  ];

  const ReportData: DataItem[] = [
    {
      id: 1,
      name: 'Question Distribution Report',
    },
    {
      id: 2,
      name: 'IRT parameters',
    },
  ];

  return (
    <div>
      <div className={`${style.search_bar} ${style.show}`}>
        <div className={style.header}>
          {setQuestionBankId === null || questionBank === null ? (
            <div className={style.header_text_title}>{title}</div>
          ) : (
            <Header
              questionBank={questionBank}
              ReportData={ReportData}
              MasterData={MasterData}
              setQuestionBankId={setQuestionBankId}
              title={title}
            />
          )}
        </div>
        <div className={style.profile}>
          <div className={style.name}>
            <h4>
            {firstName} {lastName}
            </h4>
            <p>view profile</p>
          </div>
          <div className={style.image}>
            <img src={profile} alt="Profile" width={48} height={48} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RightPanelTopMenu;
