import React, { useEffect, useState } from 'react';
import { GET_QUESTIOB_TYPE } from '../../../../../action/apiPath';
import { CallWithAuth } from '../../../../../action/apiActions';
import { QuestionCreationScreen, QuestionCreationScreenCancel } from '../../../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import MediumModal from '../../../../atoms/Modal/MediumModal';
import clozeDropdown from '../../../../../assets/images/clozewith-dropdown.svg';
import clozeMultichoice from '../../../../../assets/images/clozewith-multichoice.svg';
import selectionPerRow from '../../../../../assets/images/selectionperrow.svg';
import selectionPerLabel from '../../../../../assets/images/selectionPerLabel.svg';
import selectionPerColumn from '../../../../../assets/images/selectionPerColumn.svg';
import highlightPassage from '../../../../../assets/images/Highlight.svg';
import fractionInput from '../../../../../assets/images/fractionInput.svg';
import descriptiveText from '../../../../../assets/images/descriptiveText.svg';
import style from '../../../../../assets/styles/createQuestion.module.css';

interface DataType {
  questiontype?: {
    question_type_id?: number;
  };
  [key: string]: any;
}

const SelectType = ({
  id,
  data,
  format,
  handleQuestionType,
  index,
  selectedID,
  getData,
  questiontype,
}: {
  id: any;
  data: DataType;
  format?: any;
  handleQuestionType: (id: number) => void;
  index?: number;
  selectedID?: number | null;
  getData?: any;
  questiontype?: any;
}) => {
  // ________ initialize ________
  const location = useLocation();
  const receivedState = location.state;
  const selector = useSelector((state: any) => state.QuestionScreenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // ________ ACCORDIAN OPENER ___________
  const [open, setOpen] = useState(true);

  const openAccordian = () => {
    setOpen(true);
  };
  const closeAccordian = () => {
    setOpen(false);
  };

  //  ________ SELECT CATEGORY ________
  // const [select, setSelect] = useState(null)

  // CHOOSECATEGORY
  const SelectCategoryKey = (i: any) => {
    setSelectedQuestionCategory(i);
    // setSelect(null)
    setQuestiontypeId(null);
  };
  // CHOOSECATEGORY_ID
  const [QuestionTypeId, setQuestiontypeId] = useState<any>(null);
  const SelectCategoryId = (key: any, data: any) => {
    // setSelect(key)
    setQuestiontypeId(data);
  };

  useEffect(() => {
    setQuestiontypeId(data?.questiontype?.question_type_id ?? null);
  }, [data]);

  // ________ get category from API and grouped in sequence ________
  const [questionCategory, setQuestionCategory] = useState<{ [key: string]: any[] }>({});
  const [slectedquestionCategory, setSelectedQuestionCategory] = useState(0);
  const itemContentMap: any = {
    14: <img src={clozeDropdown} alt="clozeDropdown" />,
    15: <img src={clozeMultichoice} alt="clozeMultichoice" />,
    16: <img src={highlightPassage} alt="highlight" />,
    17: <img src={selectionPerRow} alt="selectionPerRow" />,
    18: <img src={selectionPerLabel} alt="selectionPerLabel" />,
    19: <img src={selectionPerColumn} alt="selectionPerColumn" />,
    20: <img src={fractionInput} alt="fractionInput" />,
    6: <img src={descriptiveText} alt="descriptiveText" />,
  };
  const removeHighLight = (data: any) => {
    if (selector.screen === 2.1) {
      // Iterate over the keys of the object
      for (const key in data) {
        // Check if the array under the key contains an object with id: 16
        if (data[key].some((item: any) => item.id === 16)) {
          // If found, delete the key from the object
          delete data[key];
        }
      }
    }
    return data;
  };
  const GetQuestionTypeCategory = async () => {
    const category = await CallWithAuth('GET', GET_QUESTIOB_TYPE);
    if (category.status && category.res && category.res.status === 200) {
      const groupedData: { [key: string]: any[] } = {};

      category?.res?.data.forEach((item: any) => {
        const category = item.category;
        if (!groupedData[category]) {
          groupedData[category] = [];
        }
        groupedData[category].push(item);
      });
      const data = removeHighLight(groupedData);
      setQuestionCategory(data);

      // const onlyCategory = category?.res?.data?.map(items => items.category)
      // setQuestionCategory(Array.from(new Set(onlyCategory)))
    }
  };
  useEffect(() => {
    GetQuestionTypeCategory();
  }, []);

  // SELECT QuestionTypeId SAVED to make status false
  const [saveButtonStatus, setSaveButtonStatus] = useState(true);

  useEffect(() => {
    if (QuestionTypeId === null) {
      setSaveButtonStatus(true);
    } else {
      if (QuestionTypeId === null) {
        setSaveButtonStatus(true);
      } else {
        setSaveButtonStatus(false);
      }
    }

    // if (QuestionTypeId?.id == 1 || QuestionTypeId?.id == 2) {
    //     setSelectedQuestionCategory(0)
    // } else {
    //     setSelectedQuestionCategory(1)
    // }
  }, [QuestionTypeId]);
  useEffect(() => {
    if (questiontype && questionCategory) {
      const categoryIndex = Object.keys(questionCategory)
        ?.map((items, i) => items)
        .indexOf(questiontype.category);
      setSelectedQuestionCategory(categoryIndex);
      setQuestiontypeId(questiontype);

      //body.question_type_id=questiontype
    }

    // if (QuestionTypeId?.id == 1 || QuestionTypeId?.id == 2) {
    //     setSelectedQuestionCategory(0)
    // } else {
    //     setSelectedQuestionCategory(1)
    // }
  }, [questiontype, questionCategory, screen]);

  // ________ body of the selection type  ________
  const body = {
    question_type_id: QuestionTypeId,
  };

  // _____________ MODAL OPEN AND CLOSE _____________
  const [modalMedium, setModalMedium] = useState(false);
  const [modalData, setModalModalData] = useState({
    title: 'Are You Sure, Do You Want To Cancel Question Creation?',
    description: 'Your Current Changes Will Not Be Saved And You Will Be Redirected To Question Listing ',
    cancelButton: 'Continue Editing',
    saveButton: 'Yes, Cancel',
  });

  const openModalMedium = () => {
    setModalMedium(true);
  };

  const closeModalMedium = () => {
    setModalMedium(false);
  };

  // cancel button to send the user to question-bank
  const Cancel = () => {
    navigate('/question-bank');
    dispatch(QuestionCreationScreenCancel());
  };

  const [hoveredItem, setHoveredItem] = useState(null);

  useEffect(() => {
    if (selectedID != null) {
      if (selectedID == 3 || selectedID == 4 || selectedID == 5) {
        setSelectedQuestionCategory(1);
      }
      setQuestiontypeId(selectedID);
      setHoveredItem(null);
    } else {
      if (!questiontype) setQuestiontypeId(null);
    }
  }, [selectedID]);
  useEffect(() => {
    if (getData) {
      if (
        getData?.question_type_id?.id == 3 ||
        getData?.question_type_id?.id == 4 ||
        getData?.question_type_id?.id == 5
      ) {
        setSelectedQuestionCategory(1);
      }
      setQuestiontypeId(getData?.question_type_id?.id);
    }
  }, [getData]);
  return (
    <>
      <div className={style.question_type_box} style={{ height: open ? 'auto' : '5%' }}>
        {/* ________ top of selection  ________*/}
        <div>
          <div className={style.question_type_title}>
            <span>Select Question Type</span>

            {open ? (
              <svg
                className={style.pointer}
                onClick={closeAccordian}
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.07992 16.7617L10.5999 9.96271C11.3699 9.15976 12.6299 9.15976 13.3999 9.96271L19.9199 16.7617"
                  stroke="#212529"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                className={style.pointer}
                onClick={openAccordian}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.9201 8.94922L13.4001 15.4692C12.6301 16.2392 11.3701 16.2392 10.6001 15.4692L4.08008 8.94922"
                  stroke="#212529"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
          <div></div>
        </div>

        <div className={style.category} style={{ display: open ? 'flex' : 'none' }}>
          {/* ________ sub-category by grouping the API  ________*/}
          <div className={style.sub_category}>
            {Object.keys(questionCategory)?.map((items, i) => {
              return (
                <div
                  className={
                    slectedquestionCategory === i
                      ? ` ${style.selectedquestion} ${style.pointer} textUnderLine`
                      : `${style.pointer}`
                  }
                  onClick={() => {
                    if (!questiontype?.id) SelectCategoryKey(i);
                  }}
                >
                  {items}
                </div>
              );
            })}
          </div>
          {/* ________ name-category by grouping the API  ________*/}
          <div className={style.name_category}>
            <div
              className={style.selection_box}
              style={{ marginBottom: selector.screen === 2.1 ? '0%' : '5%' }}
              key={index}
            >
              {Object.values(questionCategory)
                ?.filter((item, key) => key === slectedquestionCategory)
                .map((items, i) => (
                  <>
                    {items.map((item, key) => (
                      <div
                        className={` ${style.select_type} ${style.pointer}`}
                        onClick={() => !questiontype?.id && SelectCategoryId(key, item)}
                      >
                        <p
                          style={{
                            color: QuestionTypeId?.id === item.id || selectedID === item.id ? '#00ABFB' : '#212529',
                          }}
                        >
                          {item.name}
                        </p>

                        {hoveredItem == item?.id ? (
                          <div
                            className={`${style.select_box_type} ${style.hover}`}
                            onClick={() => {
                              !questiontype?.id && handleQuestionType(item.id);
                            }}
                            onMouseLeave={() => !questiontype?.id && setHoveredItem(null)}
                            style={{
                              borderColor: QuestionTypeId?.id === item.id ? '#00ABFB' : '#C7CCD1',
                              height: '120px',
                              width: '218px',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              className={`${style.hover_content} ${QuestionTypeId?.id === item.id ? style.active : ''}`}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="64px" height="64px">
                                <path
                                  fill="#4caf50"
                                  d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                                />
                                <path fill="#fff" d="M21,14h6v20h-6V14z" />
                                <path fill="#fff" d="M14,21h20v6H14V21z" />
                              </svg>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={style.select_box_type}
                            onClick={() => !questiontype?.id && handleQuestionType(item.id)}
                            onMouseEnter={() => !questiontype?.id && setHoveredItem(item.id)}
                            onMouseLeave={() => !questiontype?.id && setHoveredItem(null)}
                            style={{
                              borderColor:
                                QuestionTypeId?.id === item.id || selectedID === item.id ? '#00ABFB' : '#C7CCD1',
                              height: '120px',
                              width: '218px',
                              alignItems: 'center',
                            }}
                          >
                            {itemContentMap[item.id] || (
                              <>
                                <p>Which colour has the smallest wavelength?</p>
                                <div>
                                  <input type="radio" name="single-answer" id="single-answer" />
                                  <label>Red</label>
                                </div>

                                <div className={style.checked_full}>
                                  <input type="radio" name="single-answer" id="single-answer" checked />
                                  <label>Violet </label>
                                </div>

                                <div>
                                  <input type="radio" name="single-answer" id="single-answer" />
                                  <label>Green</label>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                ))}

              {/* <div className='select-type' onClick={() => setSelect(1)}>
                                <p style={{ color: select === 1 ? '#00ABFB' : "#212529" }}>Multiple Select Question</p>
                                <div className='select-box-type' style={{ borderColor: select === 1 ? "#00ABFB" : "#C7CCD1" }}>
                                    <p>Which colour has the smallest wavelength?</p>
                                    <div className='checked-full'>
                                        <input type='checkbox' checked name='single-answer' id='single-answer' />
                                        <label>Red</label>
                                    </div>


                                    <div>
                                        <input type='checkbox' name='single-answer' id='single-answer' />
                                        <label>Violet </label>
                                    </div>

                                    <div className='checked-full'>
                                        <input type='checkbox' checked name='single-answer' id='single-answer' />
                                        <label>Green</label>
                                    </div>
                                </div>
                            </div>
                            <div className='select-type' onClick={() => setSelect(2)}>
                                <p style={{ color: select === 2 ? '#00ABFB' : "#212529" }}>Select Answer For Multiple Options</p>
                                <div className='select-box-type' style={{ borderColor: select === 2 ? "#00ABFB" : "#C7CCD1" }}>
                                    <p>Which colour has the smallest wavelength?</p>
                                    <div className='checked-full'>
                                        <input type='checkbox' checked name='single-answer' id='single-answer' />
                                        <label>Red</label>
                                    </div>


                                    <div className='checked-full'>
                                        <input type='checkbox' checked name='single-answer' id='single-answer' />
                                        <label>Violet </label>
                                    </div>

                                    <div className='checked-full'>
                                        <input type='checkbox' checked name='single-answer' id='single-answer' />
                                        <label>Green</label>
                                    </div>
                                </div>
                            </div> */}
            </div>

            {/* _________ SAVE BUTTON _________ */}
            {format == 1 ? (
              ''
            ) : (
              <div className={style.saveButton_questionCreation}>
                <div className={` ${style.cancel} ${style.pointer}`} onClick={() => openModalMedium()}>
                  Cancel
                </div>
                <div>
                  <button
                    className={saveButtonStatus ? ` ${style.blue_button_disable}` : `${style.blue_button}`}
                    disabled={saveButtonStatus}
                    onClick={() => dispatch(QuestionCreationScreen(body))}
                  >
                    Save & Continue
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <MediumModal
          modalMedium={modalMedium}
          forwardButton={() => Cancel()}
          closeModalMedium={closeModalMedium}
          modalData={modalData}
        />
      </div>
    </>
  );
};

export default SelectType;
